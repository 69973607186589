// onlineOfflineNode
import { createSlice } from "@reduxjs/toolkit";
import { onlineOfflineNode } from "../../actions/alarmTripCount/onlineOfflineNode";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const onlineOfflineNodeReducer = createSlice({
  name: "onlineOfflineNodeReducer",
  initialState,
  reducers: {
    clearonlineOfflineNodeReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(onlineOfflineNode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(onlineOfflineNode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(onlineOfflineNode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearonlineOfflineNodeReducer } =
  onlineOfflineNodeReducer.actions;
export default onlineOfflineNodeReducer.reducer;
