import { createSlice } from "@reduxjs/toolkit";
import { addProductAction } from "../actions/product/addProductAction";
import { editProductAction } from "../actions/product/editProductAction";

const initialState = {
  data: [],
  editData: [],
  loading: false,
  error: null,
};

const addProductSlice = createSlice({
  name: "addNode",
  initialState,
  reducers: {
    clearaddProductState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProductAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(addProductAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editProductAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editProductAction.fulfilled, (state, action) => {
        state.loading = false;
        state.editData = action.payload;
      })
      .addCase(editProductAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearaddProductState } = addProductSlice.actions;
export default addProductSlice.reducer;
