import validator from "validator";

export const assignedItemValidation = (data) => {
  const error = {};
  if (validator.isEmpty(data.user)) {
    error.user = "Please select user";
  }
  if (data.product.length === 0) {
    error.product = "Please select node";
  }
  return error;
};
