import { createSlice } from "@reduxjs/toolkit";
import { getDataByDate } from "../../actions/admin/getDataByDate";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const getDataByDateReducer = createSlice({
  name: "getDataByDateReducer",
  initialState,
  reducers: {
    cleargetDataByDateReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDataByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDataByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleargetDataByDateReducer } = getDataByDateReducer.actions;
export default getDataByDateReducer.reducer;
