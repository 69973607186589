import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { addAssignedModule } from "../../redux/actions/addAssignedModule";
import { Toast } from "primereact/toast";
import { clearassignedModuleState } from "../../redux/reducers/addAssignedModuleReducer";
import { getAssignedLicenseAction } from "../../redux/actions/license/getAssignedLicenseAction";
import { assignedNodeValidation } from "../../validations/assignedNodeValidation";
import { getProductAction } from "../../redux/actions/product/getProductAction";

const AssignedModule = ({
  setOpenModal,
  nodeData,
  moduleData,
  modalType,
  editData,
}) => {
  const [visible, setVisible] = useState(true);
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [error, setError] = useState(null);
  const [selectedNode, setselectedNode] = useState(editData);
  const [selectedModules, setSelectedModules] = useState(
    modalType === "add"
      ? null
      : JSON.parse(editData?.assign_module).map((item) => ({
          table_name: item,
        }))
  );
  const moduleOptions =
    modalType === "add"
      ? moduleData
      : [
          ...JSON.parse(editData?.assign_module).map((item) => ({
            table_name: item,
          })),
          ...moduleData,
        ];
  // const [selectedEvent, setselectedEvent] = useState(
  //   modalType === "add"
  //     ? null
  //     : JSON.parse(editData?.assign_event).map((item) => ({
  //         table_name: item,
  //       }))
  // );
  // const eventOptions =
  //   modalType === "add"
  //     ? eventsList
  //     : [
  //         ...JSON.parse(editData?.assign_event).map((item) => ({
  //           table_name: item,
  //         })),
  //         ...eventsList,
  //       ];

  const assignedModule = useSelector((state) => state.addAssignedModuleReducer);

  const handleSubmit = () => {
    const updatedData = {
      product_id: selectedNode?.id ? String(selectedNode?.id) : "",
      assign_module: selectedModules
        ? selectedModules?.map((item) => item?.table_name)
        : [],
      // events: selectedEvent
      //   ? selectedEvent?.map((item) => item?.table_name)
      //   : [],
    };
    const errors = assignedNodeValidation(updatedData);
    setError(errors);
    if (Object.keys(errors).length === 0) {
      dispatch(addAssignedModule(updatedData));
    } else {
      setError(errors);
    }
  };

  useEffect(() => {
    if (assignedModule?.data?.status === true) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: assignedModule?.data.message,
        life: 3000,
      });
      dispatch(clearassignedModuleState());
      setTimeout(() => {
        setOpenModal(false);
        dispatch(getAssignedLicenseAction());
        dispatch(getProductAction({ value: "" }));
      }, 1500);
    } else if (assignedModule?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: assignedModule?.data.message,
        life: 3000,
      });
      dispatch(clearassignedModuleState());
    } else if (assignedModule?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: assignedModule.error,
        life: 3000,
      });
      dispatch(clearassignedModuleState());
    }
  }, [assignedModule, setOpenModal, dispatch]);

  return (
    <>
      <div className="card flex justify-content-center">
        <Dialog
          header={
            modalType === "add" ? "Add Assigned Module" : "Edit Assigned Module"
          }
          visible={visible}
          style={{ width: "80vw" }}
          draggable={false}
          onHide={() => {
            setVisible(false);
            setOpenModal(false);
          }}
        >
          <div className="row p-3">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group assigned-item">
                <div className="flex">
                  <label className="text-[14px]">Select Node</label>
                  <span className="text-red-700 pl-1">*</span>
                </div>
                <Dropdown
                  filter
                  value={selectedNode}
                  onChange={(e) => {
                    setselectedNode(e.value);
                    setError({ ...error, product_id: "" });
                  }}
                  options={
                    modalType === "add"
                      ? nodeData?.filter(
                          (i) => JSON.parse(i?.assign_module)?.length <= 0
                        )
                      : nodeData
                  }
                  optionLabel="name"
                  placeholder="Select a Node"
                  className="w-full md:w-14rem h-[3rem] !border border-[#ced4da] hover:border-[#fe4c4c] rounded-none z-100"
                />
                {error?.product_id && (
                  <p className="text-red-600 text-[12px]">
                    {error?.product_id}
                  </p>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <div className="flex">
                  <label className="text-[14px]">Select Module</label>
                  <span className="text-red-700 pl-1">*</span>
                </div>
                <div className="card flex justify-center">
                  <MultiSelect
                    title={selectedModules?.map((element) => element?.name)}
                    value={selectedModules}
                    filter
                    onChange={(e) => {
                      setSelectedModules(e.value);
                      setError({ ...error, assign_module: "" });
                    }}
                    options={moduleOptions}
                    optionLabel="table_name"
                    display="chip"
                    placeholder="Select Modules"
                    maxSelectedLabels={3}
                    className="w-full md:w-14rem h-[3rem] !border border-[#ced4da] hover:border-[#fe4c4c] rounded-none z-100"
                  />
                  {error?.assign_module && (
                    <p className="text-red-600 text-[12px]">
                      {error?.assign_module}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
              <button
                className="btn btn-light ml-1"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default AssignedModule;
