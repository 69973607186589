import React, { useState, useEffect, useRef } from "react";
import TimeSeriesChart from "../../commoncomponents/TimeSeriesChart";
import { MultiSelect } from "primereact/multiselect";
import {
  convertDataToHighchartsFormat,
  rearrangeArray,
} from "../../utils/converData";
import { useDispatch, useSelector } from "react-redux";
import getModuleColumns from "../../redux/actions/admin/getModuleColumns";
import { getModuleWiseChartData } from "../../redux/actions/admin/getModuleWiseChartData";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import Loading from "../../commoncomponents/Loading";
import { clearTimeSeriesDataState } from "../../redux/reducers/admin/getTimeSeriesDataReducer";

const includedKeys = [
  "RPhaseNeutralVoltage",
  "YPhaseNeutralVoltage",
  "BPhaseNeutralVoltage",
  "AverageVoltage",
  "RYVoltage",
  "YBVoltage",
  "BRVoltage",
  "RPhaseLineCurrent",
  "YPhaseLineCurrent",
  "BPhaseLineCurrent",
  "NeutralLineCurrent",
  "Q1PowerFactor",
  "Q2PowerFactor",
  "Q3PowerFactor",
  "AvgPowerFactor",
  "OTI",
  "WTI",
  "CTP",
  "WTI2",
  "CT2P",
  "WTI3",
  "CT3P",
  "CH1",
  "CH2",
  "CH3",
  "CH4",
  "CH5",
  "CH6",
  "CH7",
  "CH8",
  "PTVoltage",
  "TAPPosition",
  "AMB",
  "TapPosition",
  "HardwareVerison",
  "SoftwareVerison",
  "SerialNumber",
];

const GraphViewTab = ({ tabList, nId, activeParentTab }) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const savedFilter = localStorage.getItem(`dateFilter_${nId}`)?.split(",");
  const savedColumns = localStorage.getItem(`selectedColumns_${nId}`);
  const [options, setOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [currentModule, setCurrentModule] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const columnsData = useSelector((state) => state.getModuleColumnsData);
  const chartsData = useSelector((state) => state.getTimeSeriesDataReducer);

  useEffect(() => {
    dispatch(getModuleColumns(tabList?.map((t) => t.table_name)));
  }, [dispatch]);

  useEffect(() => {
    if (columnsData) {
      setOptions(columnsData?.data?.data);
    }
  }, [columnsData]);

  useEffect(() => {
    const filteredColumns = Object?.fromEntries(
      Object?.entries(selectedColumns)?.filter(
        ([key, value]) => value.length > 0
      )
    );

    let payload = {
      tableList: filteredColumns,
      startDate: startDate ?? "",
      endDate: endDate ?? "",
    };

    if (startDate && endDate) {
      const totalDays =
        new Date(endDate).getDate() - new Date(startDate).getDate();
      if (totalDays <= 15) dispatch(getModuleWiseChartData(payload));
      else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please contact admin for accessing data more than 15 days",
          life: 3000,
        });
      }
    }
  }, [selectedColumns, dispatch, startDate, endDate]);

  useEffect(() => {
    if (!chartsData?.loading && chartsData?.data?.data) {
      setIsLoading(false);
      for (let c in chartsData?.data?.data) {
        if (
          currentModule.name === c &&
          !Object.keys(chartsData?.data?.data[c]).length
        ) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `No data found in the last hour for module: ${currentModule.label}`,
            life: 3000,
          });
          continue;
        }
      }
      const formattedData = convertDataToHighchartsFormat(
        chartsData?.data?.data
      );
      const series = Object.keys(formattedData).map((key) => ({
        name: key,
        data: formattedData[key],
      }));
      setChartSeries(series);
      dispatch(clearTimeSeriesDataState());
    }
  }, [chartsData, currentModule, dispatch]);

  const handleFilterDate = () => {
    setIsLoading(true);
    const filteredColumns = Object.fromEntries(
      Object.entries(selectedColumns).filter(([key, value]) => value.length > 0)
    );
    let payload = { tableList: filteredColumns, startDate, endDate };
    const dateFilters = [startDate, endDate];
    const totalDays =
      new Date(endDate).getDate() - new Date(startDate).getDate();
    localStorage.setItem(`dateFilter_${nId}`, dateFilters);
    if (totalDays <= 15) dispatch(getModuleWiseChartData(payload));
    else {
      setIsLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please contact admin for accessing data more than 15 days",
        life: 3000,
      });
    }
  };

  const handleDayWiseData = (days) => {
    setChartSeries([]);
    let previousDate = new Date();
    if (days === 1) {
      previousDate.setDate(previousDate.getDate() - 1);
    } else if (days === 7) {
      previousDate.setDate(previousDate.getDate() - 7);
    } else if (days === 15) {
      previousDate.setDate(previousDate.getDate() - 15);
    } else if (days === 30) {
      previousDate.setDate(previousDate.getDate() - 30);
    } else if (days === 0) {
      previousDate.setHours(previousDate.getHours() - 1);
    }
    setStartDate(previousDate);
    setEndDate(new Date());
    const dateFilters = [previousDate, new Date(), days];
    localStorage.setItem(`dateFilter_${nId}`, dateFilters);
  };

  const handleChange = (tabName, moduleLabel, e) => {
    setChartSeries([]);
    if (chartsData?.loading) return;
    setCurrentModule({ name: tabName, label: moduleLabel });
    setSelectedColumns((prevSelectedColumns) => ({
      ...prevSelectedColumns,
      [tabName]: e.value,
    }));
    localStorage?.setItem(
      `selectedColumns_${nId}`,
      JSON.stringify([{ ...selectedColumns, [tabName]: e.value }])
    );
  };

  const getFilteredOptions = (options) => {
    return options?.filter((c) => includedKeys.includes(c));
  };

  useEffect(() => {
    if (!options || !tabList) return;

    const setupModule = (t) => {
      const firstOption = getFilteredOptions(
        options?.["2407139999TPR501RC01"]
      )?.map((c) => ({
        value: c,
        name: c,
      }));

      setCurrentModule({
        name: t?.table_name,
        label: t?.name,
      });

      if (!savedFilter) {
        const previousDate = new Date();
        previousDate.setHours(previousDate.getHours() - 1);
        setStartDate(previousDate);
        setEndDate(new Date());
        const dateFilters = [previousDate, new Date(), "0"];
        localStorage.setItem(`dateFilter_${nId}`, dateFilters);
      } else {
        setStartDate(new Date(savedFilter?.[0]));
        setEndDate(new Date(savedFilter?.[1]));
      }

      if (JSON.parse(savedColumns)) {
        setSelectedColumns(JSON.parse(savedColumns)?.[0]);
      } else if (firstOption && t?.table_name === "2407139999TPR501RC01") {
        setSelectedColumns((prevSelectedColumns) => ({
          ...prevSelectedColumns,
          [t?.table_name]: firstOption?.map((i) => i?.value),
        }));
      }
    };

    if (tabList.length >= 2) {
      tabList.slice(0, 2).forEach(setupModule);
    } else if (tabList.length === 1) {
      setupModule(tabList[0]);
    }
  }, [options, tabList, savedColumns]);

  return (
    <>
      {/* <div className="">
        <div className="text-end px-0">
          <button
            className="btn btn-outline-secondary md:my-0 my-2"
            onClick={() => handleDayWiseData(0)}
          >
            <span className="text-sm">
              Clear Filter
              <FontAwesomeIcon className="ml-2" icon={faFilter} />
            </span>
          </button>
        </div>
      </div> */}

      <div className="row mt-2">
        <div className="col-12">
          <Accordion>
            <AccordionTab
              className="modules-accordian"
              header={
                <span className="flex align-items-center gap-2 w-full">
                  <span className="font-bold white-space-nowrap">Modules</span>
                  <FontAwesomeIcon className="ml-auto" icon={faGear} />
                </span>
              }
            >
              <div className="flex flex-col md:flex-row justify-between mb-2">
                <div className="flex flex-col md:flex-row">
                  <div className="mb-2 md:mb-0 md:mr-2 flex flex-col items-start">
                    <label
                      htmlFor="calendar-12h-start"
                      className="text-sm font-normal mb-1"
                    >
                      Start Date:
                    </label>
                    <Calendar
                      id="calendar-12h-start"
                      value={startDate}
                      className="border-1 !border-gray-400 rounded-md w-full md:!w-[165px] !p-[6px] datePicker-table"
                      onChange={(e) => setStartDate(e.value)}
                      showButtonBar
                      hourFormat="12"
                      showTime
                    />
                  </div>
                  <div className="mb-2 md:mb-0 md:mr-2 flex flex-col items-start">
                    <label
                      htmlFor="calendar-12h-end"
                      className="text-sm font-normal mb-1"
                    >
                      End Date:
                    </label>
                    <Calendar
                      id="calendar-12h-end"
                      className="border-1 !border-gray-400 rounded-md w-full md:!w-[165px] !p-[6px] datePicker-table"
                      value={endDate}
                      onChange={(e) => setEndDate(e.value)}
                      showButtonBar
                      hourFormat="12"
                      showTime
                    />
                  </div>
                  <div className="flex items-end justify-end mb-2 md:mb-0">
                    <button
                      className="btn btn-primary w-full"
                      onClick={() => handleFilterDate()}
                    >
                      {isLoading ? (
                        <div className="px-2.5">
                          <Loading width={24} height={24} />
                        </div>
                      ) : (
                        <span>Apply</span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row">
                  <div className="flex justify-between md:justify-end md:items-end mb-2 md:mb-0">
                    <button
                      className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                        savedFilter?.[2] === "0" ? "active" : ""
                      }`}
                      onClick={() => handleDayWiseData(0)}
                    >
                      <span className="text-sm">1 Hour</span>
                    </button>
                    <button
                      className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                        savedFilter?.[2] === "1" ? "active" : ""
                      }`}
                      onClick={() => handleDayWiseData(1)}
                    >
                      <span className="text-sm">24 Hours</span>
                    </button>
                    <button
                      className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                        savedFilter?.[2] === "7" ? "active" : ""
                      }`}
                      onClick={() => handleDayWiseData(7)}
                    >
                      <span className="text-sm">7 Days</span>
                    </button>

                    <button
                      className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                        savedFilter?.[2] === "15" ? "active" : ""
                      }`}
                      onClick={() => handleDayWiseData(15)}
                    >
                      <span className="text-sm">15 Days</span>
                    </button>

                    {/* <button
                      className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                        savedFilter?.[2] === "30" ? "active" : ""
                      }`}
                      onClick={() => handleDayWiseData(30)}
                    >
                      <span className="text-sm">30 Days</span>
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="row">
                {rearrangeArray(tabList)?.map((t) =>
                  getFilteredOptions(options?.[t?.table_name])?.length > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="flex">
                        <label className="text-sm font-normal mb-0">
                          {t?.name}
                        </label>
                      </div>
                      <div className="card flex justify-center">
                        <MultiSelect
                          value={selectedColumns[t?.table_name] || []}
                          onChange={(e) =>
                            handleChange(t?.table_name, t?.name, e)
                          }
                          options={getFilteredOptions(
                            options?.[t?.table_name]
                          )?.map((c) => ({
                            value: c,
                            name: c,
                          }))}
                          optionLabel="name"
                          placeholder="Select Columns"
                          maxSelectedLabels={3}
                          className="w-full !border border-[#ced4da] hover:border-[#fe4c4c]"
                          filter
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            </AccordionTab>
          </Accordion>
          {/* <div className="row md:block hidden">
            {tabList?.map((t) =>
              getFilteredOptions(options?.[t?.table_name])?.length > 0 ? (
                <div className="col-12">
                  <div className="flex mt-2">
                    <label className="text-[16px] font-bold">{t?.name}</label>
                  </div>
                  <div className="card flex justify-center">
                    <MultiSelect
                      value={selectedColumns[t?.table_name] || []}
                      onChange={(e) => handleChange(t?.table_name, t?.name, e)}
                      options={getFilteredOptions(
                        options?.[t?.table_name]
                      )?.map((c) => ({
                        value: c,
                        name: c,
                      }))}
                      optionLabel="name"
                      placeholder="Select Columns"
                      maxSelectedLabels={3}
                      className="w-full md:w-20rem !border border-[#ced4da] hover:border-[#fe4c4c]"
                      filter
                    />
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div> */}
        </div>
        <div className={`col-12`}>
          {/* {chartsData?.loading && !chartSeries?.length ? (
            <div className="flex justify-center items-center">
              <Loading width={100} height={100} />
            </div>
          ) : ( */}
          <TimeSeriesChart
            chartSeries={chartSeries}
            loading={chartsData?.loading}
          />
        </div>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default GraphViewTab;
