import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addProductValidation } from "../../validations/addNodeValidation";
import { Toast } from "primereact/toast";
import { clearaddProductState } from "../../redux/reducers/addProductReducer";
import { addProductAction } from "../../redux/actions/product/addProductAction";
import { FormInput } from "../../commoncomponents/FormInput";
import { generateFormInputs } from "../../utils/addNodeFormInput";

const AddProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const addNode = useSelector((state) => state.addNode);

  const [data, setData] = useState({
    name: "",
    longitude: "",
    latitude: "",
    description: "",
    site_name: "",
    site_area_location: "",
    remarks: "",
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = () => {
    const errors = addProductValidation(data);
    if (Object.keys(errors).length === 0) {
      dispatch(addProductAction(data));
    } else {
      setError(errors);
    }
  };

  const formInputs = generateFormInputs(data, error, handleChange);

  useEffect(() => {
    if (addNode?.data?.status === true) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: addNode?.data.message,
        life: 3000,
      });
      setData({
        name: "",
        longitude: "",
        latitude: "",
        description: "",
        site_name: "",
        site_area_location: "",
        remarks: "",
      });
      dispatch(clearaddProductState());
    } else if (addNode?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: addNode?.data.message,
        life: 3000,
      });
      dispatch(clearaddProductState());
    } else if (addNode?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: addNode.error,
        life: 3000,
      });
      dispatch(clearaddProductState());
    }
  }, [addNode, dispatch]);

  return (
    <>
      <div className="main-panel p-3">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
            <div className="card">
              <div className="card-body rounded">
                <div className="row p-3">
                  <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                    <h5 className="text-xl">Add New Node </h5>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-6 text-end">
                    <Link to={"/manageNode"}>
                      <button className="btn btn-primary">Manage Node</button>
                    </Link>
                  </div>
                </div>
                <div className="row p-3">
                  {formInputs.map((input, index) => (
                    <div
                      className={
                        input?.name === "remarks"
                          ? "col-12"
                          : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"
                      }
                      key={`${input.name}_${index}`}
                    >
                      <FormInput
                        type={input.type}
                        labelName={input.labelName}
                        required={input.required}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder={input.placeholder}
                        error={input.error}
                      />
                    </div>
                  ))}
                </div>
                <div className="row p-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end ">
                    <button className="btn btn-primary" onClick={handleSubmit}>
                      Submit
                    </button>
                    <button
                      className="btn btn-light ml-1"
                      onClick={() => {
                        navigate("/manageNode");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default AddProduct;
