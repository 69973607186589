import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

const getEventList = createAsyncThunk(
  "admin/getEventList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API}/module-table/allEventList`,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

export default getEventList;
