// offlineDeviceName
import { createSlice } from "@reduxjs/toolkit";
import { offlineDeviceName } from "../actions/offlineDeviceName";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const offlineDeviceNameReducer = createSlice({
  name: "offlineDeviceNameReducer",
  initialState,
  reducers: {
    clearofflineDeviceNameReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(offlineDeviceName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(offlineDeviceName.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(offlineDeviceName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearofflineDeviceNameReducer } =
  offlineDeviceNameReducer.actions;
export default offlineDeviceNameReducer.reducer;
