import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { linksData } from "../utils/data";
import { clearLoginState } from "../redux/reducers/loginReducer";
import { useDispatch } from "react-redux";
import { useAudio } from "../AudioProvider";

const Sidebar = ({ showSubTab, handleSubTab, setShowSideBar }) => {
  const { role } = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetAudio } = useAudio();

  const handleLogout = () => {
    dispatch(clearLoginState());
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userData");
    localStorage.removeItem("showPause");
    resetAudio();
    navigate("/");
  };
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="sidebar-menu">
        <li>
          <div className="card-header border-none pb-1 bg-white">
            <Link
              className="card-link py-0"
              to="/home"
              onClick={() => setShowSideBar(false)}
            >
              Dashboard
            </Link>
          </div>
        </li>
        {/* {role === 0 ? ( */}
        <>
          <li>
            <div className="card-header border-none pb-1 pt-0 bg-white">
              <Link
                to={"/control-panel/devices"}
                className="card-link mb-2 py-0"
                onClick={() => setShowSideBar(false)}
              >
                Control Panel
              </Link>
            </div>
          </li>
          <li>
            <div className="card-header border-none pb-1 pt-0 bg-white">
              <Link
                to={"/device-list"}
                className="card-link mb-2 py-0"
                onClick={() => setShowSideBar(false)}
              >
                Device Lists
              </Link>
            </div>
          </li>
          <li>
            <div className="card-header border-none pb-1 pt-0 bg-white">
              <Link
                to="/device-status"
                className="card-link mb-2 py-0"
                onClick={() => setShowSideBar(false)}
              >
                Devices Status
              </Link>
            </div>
          </li>
          {role === 0 && (
            <li>
              <div className="w-full">
                <div className="card-header border-none py-0 bg-white">
                  <Link
                    className="card-link py-0 text-[1rem]"
                    onClick={() => handleSubTab("tab1")}
                  >
                    System Settings
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon={showSubTab?.tab1 ? faChevronUp : faChevronDown}
                      />
                    </div>
                  </Link>
                </div>
                {showSubTab?.tab1 && (
                  <div className="card-body pt-1 ms-3 text-md">
                    <div className="row">
                      {linksData?.map((link, index) => (
                        <div
                          key={index}
                          className="col-lg-12 col-md-12 col-sm-12 col-12 mb-1"
                        >
                          <Link
                            to={link.to}
                            className="card-link"
                            onClick={() => setShowSideBar(false)}
                          >
                            {link.label}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </li>
          )}
          <div
            className="dropdown-item card-link  text-[#FE4C4C] cursor-pointer md:hidden block"
            onClick={handleLogout}
          >
            Logout
          </div>
        </>
        {/* ) : ( */}
        {/* <>
             <div className="card">
               <div className="card-header py-0 border-none bg-white mb-1">
                 <Link
                  className="card-link py-0"
                  onClick={() => handleSubTab("tab1")}
                >
                  Device List
                  <div className="float-right">
                    <FontAwesomeIcon
                      icon={showSubTab?.tab1 ? faChevronUp : faChevronDown}
                    />
                  </div>
                </Link>
              </div>
              {showSubTab?.tab1 && (
                <div className="ml-2 card-body py-0">
                  <div className="row">
                    {assign_product?.map((p, index) => (
                      <div
                        key={index}
                        className="col-lg-12 col-md-12 col-sm-12 col-12"
                      >
                        <Link
                          to={`/Details/${p?.id}/${id}`}
                          className="card-link text-sm"
                          onClick={() => setShowSideBar(false)}
                        >
                          {p?.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </> */}
        {/* )} */}
        {/* <li>
          {role === 0 ? (
            <div className="card">
              <div className="card-header">
                <Link onClick={() => handleSubTab("tab2")}>
                  Reports
                  <div className="float-right">
                    <FontAwesomeIcon
                      icon={showSubTab?.tab2 ? faChevronUp : faChevronDown}
                    />
                  </div>
                </Link>
              </div>
              {showSubTab?.tab2 && (
                <div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <Link
                          to="/details"
                          className="card-link"
                          onClick={() => setShowSideBar(false)}
                        >
                          Device Lists
                        </Link>
                      </div>
                      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <Link to="/home" className="card-link">
                        All Alerts Data
                      </Link>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <Link to="/home" className="card-link">
                        Notification History
                      </Link>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <Link to="/home" className="card-link">
                        Charts
                      </Link>
                    </div> 
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Link
              className="!pt-0"
              to={`/details/${assign_product?.[0]}/${id}`}
            >
              Reports
            </Link>
          )}
        </li> */}
      </ul>
      {/* <ul className="absolute bottom-2">
        <li>
          <p className="pl-4 font-bold">Welcome, {name}</p>
        </li>
      </ul> */}
    </nav>
  );
};

export default Sidebar;
