import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../redux/actions/loginAction";
import { loginValidation } from "../validations/loginValidation";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { clearLoginState } from "../redux/reducers/loginReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ForgotPasswordModal from "../commoncomponents/ForgotPasswordModal";

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [errData, setErrData] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [forGotPassModal, setForGotPassModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  const loginReducer = useSelector((state) => state.login);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrData(null);
    if (name === "email") {
      setData((prev) => ({ ...prev, email: value }));
    }
    if (name === "password") {
      setData((prev) => ({ ...prev, password: value }));
    }
  };

  const handleLogin = () => {
    const errors = loginValidation(data);
    if (Object.keys(errors).length === 0) {
      dispatch(loginAction(data));
    } else {
      setErrData(errors);
    }
  };

  useEffect(() => {
    if (loginReducer.data[0]?.access_token) {
      navigate("/home");
    } else if (loginReducer.data[0]?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: loginReducer.data[0]?.message,
        life: 3000,
      });
      dispatch(clearLoginState());
    }
  }, [loginReducer, navigate, dispatch]);

  return (
    <>
      <main className="flex items-center min-vh-100 py-3 py-md-0 login-background">
        <div className="container lg:max-w-[1140px]">
          <div className="login-card">
            <div className="row no-gutters">
              <div className="col-md-6 col-lg-6 col-xl-6 md:mt-0 mt-4">
                <div className="flex justify-center h-100 items-center">
                  {/* <img
                    className="md:block hidden"
                    src="./login.jpg"
                    alt="login"
                  /> */}
                  <div className="text-center mb-2 px-4">
                    <h3 className="text-[1.75rem]	font-semibold text-black">
                      Transformer
                    </h3>
                    <h3 className="text-[1.75rem]	font-semibold text-black">
                      Operation, Monitoring and Life Analysis 
                    </h3>
                    <p>
                      <span className="text-[1.75rem]	font-semibold text-[#FE4C4C]">
                        (TOMLA)
                        <sup className="top-[-16px] left-[-2px] text-xs">
                          TM
                        </sup>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6">
                <div className="card-body p-5">
                  <div className="flex justify-center mb-2">
                    <img
                      src="https://embelink.com/include/images/1.png"
                      alt="logo"
                      width={250}
                      className="logo"
                    />
                  </div>
                  <p className="login-card-description mb-4 text-center text-black">
                    Sign into your account
                  </p>
                  <div id="login-box" className="mb-4">
                    <div className="form-group">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        id="email"
                        className="form-control mb-0"
                        placeholder="Email address"
                      />
                      {errData?.email && (
                        <p className="text-red-600">{errData?.email}</p>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <div className="relative flex items-center">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name="password"
                          id="password"
                          onChange={handleChange}
                          className="form-control mb-0 pr-10 "
                          placeholder="***********"
                        />
                        <button
                          type="button"
                          className="absolute right-0 pr-3 focus:outline-none"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <FontAwesomeIcon
                            icon={passwordVisible ? faEye : faEyeSlash}
                          />
                        </button>
                      </div>
                      {errData?.password && (
                        <p className="text-red-600">{errData?.password}</p>
                      )}
                    </div>
                    <button
                      id="login"
                      className="btn btn-dark mb-2 w-full"
                      onClick={handleLogin}
                      disabled={loginReducer.loading}
                    >
                      {!loginReducer.loading ? "Login" : "Loading..."}
                    </button>
                    <button
                      onClick={() => setForGotPassModal(true)}
                      className="forgot-password-link hover:underline text-black"
                    >
                      Forgot password?
                    </button>
                  </div>
                  {/* <nav className="login-card-footer-nav">
                    <a href="#!" className="hover:underline">
                      Terms of use.
                    </a>
                    <a href="#!" className="hover:underline pl-1">
                      Privacy policy
                    </a>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Toast ref={toast} className="text-sm" />
      {forGotPassModal && (
        <ForgotPasswordModal
          forGotPassModal={forGotPassModal}
          setForGotPassModal={setForGotPassModal}
        />
      )}
    </>
  );
};

export default Login;
