import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addCategoryValidation } from "../../validations/addCategoryValidation";
import { addCategoryAction } from "../../redux/actions/addCategoryAction";
import { Toast } from "primereact/toast";
import { clearaddCategoryState } from "../../redux/reducers/addCategoryReducer";

const AddCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [data, setData] = useState({ category: "" });

  const [error, setError] = useState(null);

  const categoryReducer = useSelector((state) => state.addCategory.data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError(null);
  };

  const handleSubmit = () => {
    const errors = addCategoryValidation(data);
    if (Object.keys(errors).length === 0) {
      dispatch(addCategoryAction(data));
    } else {
      setError(errors);
    }
  };

  useEffect(() => {
    if (categoryReducer?.status) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: categoryReducer.message,
        life: 3000,
      });
      setData({ category: "" });
      dispatch(clearaddCategoryState());
    } else if (categoryReducer?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: categoryReducer.message,
        life: 3000,
      });
    }
  }, [categoryReducer, dispatch]);

  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper p-3">
          <div className="container-fluid p-0">
            <div className="height-100">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <div className="card caption-top ">
                    <div className="card-body rounded">
                      <div className="row p-3">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                          <h5 className="text-xl">Add New Category </h5>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-6 text-end">
                          <Link to="/manageCategory">
                            <button className="btn btn-primary">
                              Manage Category
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="row p-3">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                          <div className="form-group">
                            <div className="flex">
                              <label className="text-[14px]">Add Cateory</label>
                              <span className="text-red-700 pl-1">*</span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="category"
                              name="category"
                              placeholder="Enter Category Name"
                              onChange={handleChange}
                              value={data.category}
                            />
                            {error?.category && (
                              <p className="text-red-600 text-[12px]">
                                {error?.category}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row p-3">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end">
                          <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                          <button
                            className="btn btn-light ml-1"
                            onClick={() => {
                              navigate("/manageCategory");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default AddCategory;
