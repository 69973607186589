export const generateData = (minutes, type) => {
  const baseDate = new Date();
  const keys = type.includes("ELITE300")
    ? [
        "Sr. No.",
        "DateTime",
        "YPhaseNeutralVoltage",
        "RPhaseNeutralVoltage",
        "BPhaseNeutralVoltage",
        "AverageVoltage",
        "RYVoltage",
        "YBVoltage",
        "BRVoltage",
        "RPhaseLineCurrent",
        "YPhaseLineCurrent",
        "BPhaseLineCurrent",
        "NeutralLineCurrent",
        "Q1PowerFactor",
        "Q2PowerFactor",
        "Q3PowerFactor",
        "AvgPowerFactor",
        "RPhaseActivePower",
        "YPhaseActivePower",
        "BPhaseActivePower",
        "ThreePhaseActivePower",
        "RPhaseReaivePower",
        "YPhaseReaivePower",
        "BPhaseReaivePower",
        "ThreePhaseReaivePower",
        "RPhaseApparentPower",
        "YPhaseApparentPower",
        "BPhaseApparentPower",
        "ThreePhaseApparentPower",
        "CurrentVoltageAngleRphase",
        "CurrentVoltageAngleYphase",
        "CurrentVoltageAngleBphase",
        "FrequencyHz",
        "MaximumActivePowerRPhase",
        "MinimumActivePowerRPhase",
        "MaximumActivePowerYPhase",
        "MinimumActivePowerYPhase",
        "MaximumActivePowerBPhase",
        "MinimumActivePowerBPhase",
        "MaximumReaivePowerRPhase",
        "MinimumReaivePowerRPhase",
        "MaximumReaivePowerYPhase",
        "MinimumReaivePowerYPhase",
        "MaximumReaivePowerBPhase",
        "MinimumReaivePowerBPhase",
        "MaximumApparentPowerRPhase",
        "MinimumApparentPowerRPhase",
        "MaximumApparentPowerYPhase",
        "MinimumApparentPowerYPhase",
        "MaximumApparentPowerBPhase",
        "MinimumApparentPowerBPhase",
        "created_at",
      ]
    : type.includes("TPR702RC")
    ? [
        "Sr. No.",
        "DateTime",
        "OTI",
        "WTI",
        "WTI2",
        "CT_Amp",
        "CT2_Amp",
        "CTP",
        "CT2P",
        "CT_Ratio",
        "CT2_Ratio",
        "RLYOTA",
        "RLYOTT",
        "RLYWTA",
        "RLYWTT",
        "RLYWT2A",
        "RLYWT2T",
        "OTI_ARSP",
        "OTI_TRSP",
        "WTI_ARSP",
        "WTI_TRSP",
        "WT2I_ARSP",
        "WT2I_TRSP",
        "OTI_MAX",
        "WTI_MAX",
        "WT2I_MAX",
        "DT",
        "DT2",
        "TTC",
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ]
    : type.includes("TPR703RC")
    ? [
        "Sr. No.",
        "DateTime",
        "OTI",
        "WTI",
        "WTI2",
        "WTI3",
        "CT_Amp",
        "CT2_Amp",
        "CT3_Amp",
        "CTP",
        "CT2P",
        "CT3P",
        "CT_Ratio",
        "CT2_Ratio",
        "CT3_Ratio",
        "RLYOTA",
        "RLYOTT",
        "RLYWTA",
        "RLYWTT",
        "RLYWT2A",
        "RLYWT2T",
        "RLYWT3A",
        "RLYWT3T",
        "OTI_ARSP",
        "OTI_TRSP",
        "WTI_ARSP",
        "WTI_TRSP",
        "WT2I_ARSP",
        "WT2I_TRSP",
        "WT3I_ARSP",
        "WT3I_TRSP",
        "OTI_MAX",
        "WTI_MAX",
        "WT2I_MAX",
        "WT3I_MAX",
        "DT",
        "DT2",
        "DT3",
        "TTC",
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ]
    : type.includes("AVR302RC")
    ? [
        "Sr. No.",
        "DateTime",
        "PTVoltage",
        "TAPPosition",
        "LRSP",
        "RRSP",
        "UVSP",
        "OVSP",
        "RecoveryVoltage",
        "T1",
        "T2",
        "ROnTime",
        "DisplayVotageMultiple",
        "MODE",
        "ForceHoldRelay",
        "RaiseRelay",
        "LowerRelay",
        "UnderVoltage",
        "OverVoltage",
        "MaximumVoltage",
        "MinimumVoltage",
        "LRCounter",
        "RRCounter",
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ]
    : type.includes("TPR721RC")
    ? [
        "Sr. No.",
        "DateTime",
        "OTI",
        "WTI",
        "AMB",
        "CT_Amp",
        "CTP",
        "CT_Ratio",
        "RLYOTA",
        "RLYOTT",
        "RLYWTA",
        "RLYWTT",
        "OTI_ARSP",
        "OTI_TRSP",
        "WTI_ARSP",
        "WTI_TRSP",
        "OTI_MAX",
        "WTI_MAX",
        "AMB_MAX",
        "DT",
        "TTC",
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ]
    : type.includes("Events")
    ? [
        "Sr. No.",
        "PrimKey",
        "EventStartedOn",
        "EventEndedOn",
        "EventOnModule",
        "EventTriggered",
        "status",
        "created_at",
      ]
    : type.includes("TPI540RC")
    ? [
        "Sr. No.",
        "DateTime",
        "TapPosition",
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ]
    : type.includes("IR101C")
    ? [
        "Sr. No.",
        "DateTime",
        "Buchholz Alarm",
        "Buchholz Trip", // Relay2
        "MOG Alarm", // Relay3
        "OLTC/TANK PRV Trip", // Relay4
        "OLTC OSR Trip", // Relay5
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ]
    : type.includes("Events")
    ? [
        "Sr. No.",
        "PrimKey",
        "EventStartedOn",
        "EventEndedOn",
        "EventOnModule",
        "EventTriggered",
        "status",
        "created_at",
      ]
    : [
        "Sr. No.",
        "DateTime",
        "OTI",
        "WTI",
        "CT_Amp",
        "CTP",
        "CT_Ratio",
        "RLYOTA",
        "RLYOTT",
        "RLYWTA",
        "RLYWTT",
        "OTI_ARSP",
        "OTI_TRSP",
        "WTI_ARSP",
        "WTI_TRSP",
        "OTI_MAX",
        "WTI_MAX",
        "DT",
        "TTC",
        "HardwareVerison",
        "SoftwareVerison",
        "SerialNumber",
        "created_at",
      ];

  const generateDateTimeString = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-GB", options).replace(",", "");
  };

  const generateRecord = (id, dateTime) => {
    let record = { "Sr. No.": id.toString() };
    record["DateTime"] = dateTime;
    record["created_at"] = dateTime;
    keys.forEach((key) => {
      if (key !== "Sr. No." && key !== "DateTime" && key !== "created_at") {
        record[key] = "-";
      }
    });
    return record;
  };

  const data = [];
  for (let i = 0; i < minutes; i++) {
    const date = new Date(baseDate.getTime() - i * 60000); // increment by one minute
    const dateTimeString = generateDateTimeString(date);
    const record = generateRecord(i + 1, dateTimeString);
    data.push(record);
  }

  return data;
};

export const transformData = (originalData) => {
  const transformedData = [];
  originalData.forEach((item) => {
    JSON.parse(item.assign_product)?.forEach((product) => {
      transformedData.push({
        userId: item.id,
        name: item.name,
        node_name: product.name,
        node_id: product.id,
        isActive: product.isActive,
        description: product.description ?? "-",
        site_name: product.site_name ?? "-",
        site_area_location: product.site_area_location ?? "-",
      });
    });
  });
  return transformedData;
};

export const relays = {
  Relay1: "Buchholz Alarm",
  Relay2: "Buchholz Trip",
  Relay3: "MOG Alarm",
  Relay4: "OLTC/TANK PRV Trip",
  Relay5: "OLTC OSR Trip",
};
