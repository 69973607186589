import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { adduserValidation } from "../../validations/adduserValidation";
import { useDispatch, useSelector } from "react-redux";
import { addUserAction } from "../../redux/actions/addUserAction";
import { Toast } from "primereact/toast";
import { clearaddUserState } from "../../redux/reducers/addUserReducer";
import { FormInput } from "../../commoncomponents/FormInput";
import { userForm } from "../../utils/addUserFormFields";

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const initialData = {
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    mobile: "",
    password: "",
    gst: "",
    state: "",
    city: "",
    pin_code: "",
    lat_long: "",
    confirm_password: "",
    address: "",
    emailForNotification: "",
  };

  const userReducer = useSelector((state) => state.addUser);

  const [data, setData] = useState(initialData);

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = () => {
    const errors = adduserValidation(data);
    if (Object.keys(errors).length === 0) {
      dispatch(addUserAction(data));
    } else {
      setError(errors);
    }
  };

  const userFields = userForm(data, error, handleChange);

  useEffect(() => {
    if (userReducer?.data?.status) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: userReducer?.data?.message,
        life: 3000,
      });
      setData(initialData);
      dispatch(clearaddUserState());
    } else if (userReducer?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: userReducer?.data?.message,
        life: 3000,
      });
      dispatch(clearaddUserState());
    } else if (userReducer?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: userReducer?.error,
        life: 3000,
      });
      dispatch(clearaddUserState());
    }
  }, [userReducer, dispatch]);

  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper p-3">
          <div className="container-fluid p-0">
            <div className="height-100">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <div className="card caption-top ">
                    <div className="card-body rounded">
                      <div className="row p-2">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                          <h5 className="text-xl">Add New User </h5>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-6 text-end">
                          <Link to="/manageUser">
                            <button className="btn btn-primary">
                              Manage User
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="row p-3">
                        {userFields.map((input, index) => (
                          <div
                            className={
                              input?.name === "address"
                                ? "col-12"
                                : "col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"
                            }
                            key={`${input.name}_${index}`}
                          >
                            <FormInput
                              labelName={input.label}
                              required={input.required}
                              type={input.type}
                              name={input.name}
                              value={input.value}
                              onChange={input.onChange}
                              placeholder={input.placeholder}
                              error={input.error}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="row p-3">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end ">
                          <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                          <button
                            className="btn btn-light ml-1"
                            onClick={() => {
                              navigate("/manageUser");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} className="text-sm" />
    </>
  );
};

export default AddUser;
