// getChartData
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

export const getChartData = createAsyncThunk(
  "admin/getChartData",
  async (tableId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API}/module-table/chart/${tableId}`,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
