import React, { useState } from "react";
import { Paginator } from "primereact/paginator";

const Pagination = ({ totalPages, onPageChange }) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const handleChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    onPageChange(event.page + 1);
  };

  // if (totalPages === 0) return null;

  // const pageNumbers = [];
  // const renderPageNumber = (page) => (
  //   <button
  //     key={page}
  //     onClick={() => onPageChange(page)}
  //     style={{
  //       padding: "8px",
  //       margin: "0 4px",
  //       backgroundColor: page === currentPage ? "#007BFF" : "#FFFFFF",
  //       color: page === currentPage ? "#FFFFFF" : "#000000",
  //       cursor: "pointer",
  //     }}
  //   >
  //     {page}
  //   </button>
  // );
  // const renderPrevButton = () => (
  //   <button
  //     onClick={() => onPageChange(currentPage - 1)}
  //     disabled={currentPage === 1}
  //     style={{
  //       padding: "8px",
  //       marginRight: "4px",
  //       backgroundColor: "#FFFFFF",
  //       color: "#000000",
  //       cursor: currentPage === 1 ? "not-allowed" : "pointer",
  //     }}
  //   >
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="16"
  //       height="16"
  //       fill="currentColor"
  //       class="bi bi-chevron-left"
  //       viewBox="0 0 16 16"
  //     >
  //       <path
  //         fill-rule="evenodd"
  //         d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
  //       />
  //     </svg>
  //   </button>
  // );

  // const renderNextButton = () => (
  //   <button
  //     onClick={() => onPageChange(currentPage + 1)}
  //     disabled={currentPage === totalPages}
  //     style={{
  //       padding: "8px",
  //       marginLeft: "8px",
  //       backgroundColor: "#FFFFFF",
  //       color: "#000000",
  //       cursor: currentPage === totalPages ? "not-allowed" : "pointer",
  //     }}
  //   >
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="16"
  //       height="16"
  //       fill="currentColor"
  //       class="bi bi-chevron-right"
  //       viewBox="0 0 16 16"
  //     >
  //       <path
  //         fill-rule="evenodd"
  //         d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
  //       />
  //     </svg>
  //   </button>
  // );

  // let startPages = [];
  // switch (totalPages) {
  //   case 1:
  //     startPages = [];
  //     break;
  //   case 2:
  //     startPages = [];
  //     break;
  //   case 3:
  //     startPages = [1];
  //     break;
  //   default:
  //     startPages = [1, 2];
  // }
  // const endPages = [totalPages - 1, totalPages];
  // const hasLeftEllipsis = currentPage > 4;
  // const hasRightEllipsis = currentPage < totalPages - 3;

  // pageNumbers.push(renderPrevButton());

  // startPages.forEach((page) => {
  //   pageNumbers.push(renderPageNumber(page));
  // });
  // if (hasLeftEllipsis) {
  //   pageNumbers.push(<span key="left-ellipsis">...</span>);
  // }

  // let rangeStart = Math.max(3, currentPage - 1);
  // let rangeEnd = Math.min(totalPages - 2, currentPage + 1);

  // if (totalPages > 5) {
  //   for (let i = rangeStart; i <= rangeEnd; i++) {
  //     if (i !== 1 && i !== totalPages) {
  //       pageNumbers.push(renderPageNumber(i));
  //     }
  //   }
  // }

  // if (hasRightEllipsis) {
  //   pageNumbers.push(<span key="right-ellipsis">...</span>);
  // }
  // endPages.forEach((page) => {
  //   pageNumbers.push(renderPageNumber(page));
  // });

  // pageNumbers.push(renderNextButton());

  return (
    <div className="card">
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalPages * 10}
        // rowsPerPageOptions={[10, 20, 30]}
        onPageChange={handleChange}
      />
    </div>
  );
};

export default Pagination;
