import GaugeComponent from "react-gauge-component";
import { graphCTPTicks, graphTicks, standardTicks } from "../utils/data";

const NodeWiseGuageChart = ({
  parentDivKey,
  guageKey,
  nodeName,
  guageTitle,
  guageValue,
  maxValue,
}) => {
  let subArcs = [];
  let ticks = [];
  let minValue;

  if (nodeName === "TPR501RC" || nodeName === "TPR104RC") {
    subArcs = [
      {
        limit: 90,
        color: "#5BE12C",
        showTick: true,
      },
      {
        limit: 110,
        color: "#F5CD19",
      },
      {
        color: "#EA4228",
      },
    ];
    ticks =
      guageTitle === "CT-Percentage" ||
      guageTitle === "CT2-Percentage" ||
      guageTitle === "CT2P"
        ? graphCTPTicks
        : graphTicks;
    minValue =
      guageTitle === "CT-Percentage" ||
      guageTitle === "CT2-Percentage" ||
      guageTitle === "CT2P"
        ? 0
        : -50;
  } else if (nodeName === "TPR104RC") {
    subArcs = [
      {
        limit: 180,
        color: "#5BE12C",
        showTick: true,
      },
      {
        limit: 240,
        color: "#F5CD19",
      },
      {
        color: "#EA4228",
      },
    ];
    ticks = standardTicks;
  } else if (nodeName === "TPI540RC") {
    ticks = [
      { value: 0 },
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 20 },
      { value: 25 },
      { value: 30 },
      { value: 35 },
      { value: 40 },
      { value: 45 },
    ];
    minValue = 0;
    subArcs = [
      {
        color: "#DDDD",
      },
    ];
  }
  return (
    <div
      className={nodeName !== "TPI540RC" ? "col-12 col-md-6" : ""}
      key={parentDivKey}
    >
      <GaugeComponent
        key={guageKey}
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          subArcs: subArcs,
        }}
        pointer={{
          color: nodeName === "TPI540RC" ? "#DDDDD" : "#345243",
          length: 0.8,
          width: 15,
        }}
        labels={{
          valueLabel: {
            formatTextValue: (value) => value,
            style: {
              fill: "cyan",
              textShadow: "none",
              stroke: "CaptionText",
              strokeWidth: 0.8,
              fontWeight: "bolder",
              fontSize: 44,
              display: "none",
            },
          },
          tickLabels: {
            type: "outer",
            valueConfig: {
              formatTextValue: (value) => value + "ºC",
              fontSize: nodeName === "TPR104RC" ? 8 : 10,
            },
            ticks: ticks,
            defaultTickValueConfig: {
              style: { fill: "black" },
            },
          },
        }}
        value={guageValue}
        minValue={minValue}
        maxValue={maxValue}
      />
      <p className="text-center text-2xl font-bold mt-0 text-primary">
        {guageValue}
      </p>
      <p className="text-center text-2xl font-bold mt-0">{guageTitle}</p>
    </div>
  );
};

export default NodeWiseGuageChart;
