import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./reducers/loginReducer";
import addUserReducer from "./reducers/addUserReducer";
import addCityReducer from "./reducers/addCityReducer";
import addProductReducer from "./reducers/addProductReducer";
import getProductReducer from "./reducers/reducer/getProductReducer";
import getCategoriesReducer from "./reducers/categories/getCategoriesReducer";
import getUsersReducer from "./reducers/users/getUsersReducer";
import addCategoryReducer from "./reducers/categories/addCategoryReducer";
import addAssignedLicenseReducer from "./reducers/license/addAssignedLicenseReducer";
import getAssignedLicenseReducer from "./reducers/license/getAssignedLicenseReducer";
import accessModuleReducer from "./reducers/admin/accessModuleReducer";
import getDataByDateReducer from "./reducers/admin/getDataByDateReducer";
import getUsersWithNodeReducer from "./reducers/admin/getUsersWithNodeReducer";
import getChartDataReducer from "./reducers/admin/getChartDataReducer";
import addAssignedModuleReducer from "./reducers/addAssignedModuleReducer";
import updateUserReducer from "./reducers/updateUserReducer";
import getEventListReducer from "./reducers/events/getEventListReducer";
import addAssignedEventReducer from "./reducers/events/addAssignedEventReducer";
import accessEventsReducer from "./reducers/events/accessEventsReducer";
import getEventsTableReducer from "./reducers/events/getEventsTableReducer";
import updateEventStatusReducer from "./reducers/events/updateEventStatusReducer";
import updateUserStatusReducer from "./reducers/users/updateUserStatusReducer";
import getDataInXslxReducer from "./reducers/admin/getDataInXslxReducer";
import getModuleColumnsReducer from "./reducers/admin/getModuleColumnsReducer";
import getTimeSeriesDataReducer from "./reducers/admin/getTimeSeriesDataReducer";
import alarmTripCountReducer from "./reducers/alarmTripCount/alarmTripCountReducer";
import onlineOfflineCountReducer from "./reducers/alarmTripCount/onlineOfflineCountReducer";
import totalAlarmCountReducer from "./reducers/alarmTripCount/totalAlarmCountReducer";
import onlineOfflineNodeReducer from "./reducers/alarmTripCount/onlineOfflineNodeReducer";
import offlineDeviceNameReducer from "./reducers/offlineDeviceNameReducer";
import forgotPasswordReducer from "./reducers/forgotPassword/forgotPasswordReducer";
import onlineOfflineDeviceReducer from "./reducers/controlPanel/onlineOfflineDeviceReducer";
import nodeListReducer from "./reducers/controlPanel/nodeListReducer";
import alarmListReducer from "./reducers/controlPanel/alarmListReducer";
import tripListReducer from "./reducers/controlPanel/tripListReducer";

const store = configureStore({
  reducer: {
    login: loginReducer,
    addUser: addUserReducer,
    addCity: addCityReducer,
    addNode: addProductReducer,
    addCategory: addCategoryReducer,
    productsData: getProductReducer,
    categoriesData: getCategoriesReducer,
    getUsers: getUsersReducer,
    addAssignedLicense: addAssignedLicenseReducer,
    getAssignedLicense: getAssignedLicenseReducer,
    addAssignedModuleReducer: addAssignedModuleReducer,
    accessModule: accessModuleReducer,
    getDataByDateReducer: getDataByDateReducer,
    getUsersWithNodeReducer: getUsersWithNodeReducer,
    getChartDataReducer: getChartDataReducer,
    updateUserReducer: updateUserReducer,
    eventsData: getEventListReducer,
    addAssignedEventReducer: addAssignedEventReducer,
    accessEvents: accessEventsReducer,
    getEventsTable: getEventsTableReducer,
    updateEventStatusData: updateEventStatusReducer,
    updateUserStatusData: updateUserStatusReducer,
    getDataInXslxData: getDataInXslxReducer,
    getModuleColumnsData: getModuleColumnsReducer,
    getTimeSeriesDataReducer: getTimeSeriesDataReducer,
    alarmTripCountReducer: alarmTripCountReducer,
    onlineOfflineCountReducer: onlineOfflineCountReducer,
    totalAlarmCountReducer: totalAlarmCountReducer,
    onlineOfflineNodeReducer: onlineOfflineNodeReducer,
    offlineDeviceNameReducer: offlineDeviceNameReducer,
    forgotPasswordReducer: forgotPasswordReducer,
    onlineOfflineDeviceReducer: onlineOfflineDeviceReducer,
    nodeListData: nodeListReducer,
    alarmListData: alarmListReducer,
    tripListData: tripListReducer,
  },
});

export default store;
