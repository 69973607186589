export const generateFormInputs = (data, error, handleChange) => [
  {
    labelName: "Node Name",
    required: true,
    type: "string",
    name: "name",
    value: data?.name,
    onChange: handleChange,
    placeholder: "Node Name",
    error: error?.name,
  },
  {
    labelName: "Description",
    required: true,
    type: "string",
    name: "description",
    value: data?.description,
    onChange: handleChange,
    placeholder: "Description",
    error: error?.description,
  },
  {
    labelName: "Location Longitude",
    required: true,
    type: "number",
    name: "longitude",
    value: data?.longitude,
    onChange: handleChange,
    placeholder: "Longitude",
    error: error?.longitude,
  },
  {
    labelName: "Location Latitude",
    type: "number",
    required: true,
    name: "latitude",
    value: data?.latitude,
    onChange: handleChange,
    placeholder: "Latitude",
    error: error?.latitude,
  },
  {
    labelName: "Site Name",
    required: true,
    type: "string",
    name: "site_name",
    value: data?.site_name,
    onChange: handleChange,
    placeholder: "Site Name",
    error: error?.site_name,
  },
  {
    labelName: "Site Area Location",
    type: "string",
    name: "site_area_location",
    value: data?.site_area_location,
    onChange: handleChange,
    placeholder: "Site Area Location",
    error: error?.site_area_location,
  },
  {
    labelName: "Remarks",
    required: true,
    type: "string",
    name: "remarks",
    value: data?.remarks,
    onChange: handleChange,
    placeholder: "Remarks",
    error: error?.remarks,
  },
];
