import { createSlice } from "@reduxjs/toolkit";
import { updateUserStatus } from "../../actions/users/updateUserStatus";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const updateUserStatusSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    clearUpdateUserState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUpdateUserState } = updateUserStatusSlice.actions;
export default updateUserStatusSlice.reducer;
