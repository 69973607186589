import { ProgressSpinner } from "primereact/progressspinner";
import GaugeComponent from "react-gauge-component";
import { useSelector } from "react-redux";
const LiveChartTab = ({ tabIndex }) => {
  const chartData = useSelector((state) => state?.getChartDataReducer?.data);
  const keys = ["OTI", "WTI", "CTP", "CT2P", "WTI2"];
  const btnKeys = [
    "RLYOTA",
    "RLYOTT",
    "RLYWTA",
    "RLYWTT",
    "RLYWT2A",
    "RLYWT2T",
  ];

  const guageArr = keys.map((key) => ({
    title: key,
    value: chartData?.data?.[0][key],
  }));
  const btnArr = btnKeys.map((key) => ({
    title: key,
    value: chartData?.data?.[0][key],
  }));

  if (chartData === undefined) {
    return;
  }

  const date = new Date(chartData?.data?.[0]?.created_at);

  const options = {
    weekday: "long", // e.g., Monday
    year: "numeric", // e.g., 2024
    month: "long", // e.g., April
    day: "numeric", // e.g., 14
    hour: "2-digit", // e.g., 02
    minute: "2-digit", // e.g., 47
    second: "2-digit", // e.g., 34
  };

  const formattedDate = date.toLocaleDateString("en-US", options);
  return (
    <>
      <div className="container">
        <p className="text-md mt-3 text-right">Timestamp: {formattedDate}</p>
        <div className="row">
          {guageArr?.map((k) => {
            if (!k.value) {
              return <></>;
            }
            return (
              <div className="col-sm-12 col-md-4 col-lg-4">
                <GaugeComponent
                  type="semicircle"
                  arc={{
                    width: 0.2,
                    padding: 0.005,
                    cornerRadius: 1,
                    // gradient: true,
                    subArcs: [
                      {
                        limit: -10,
                        color: "#EA4228",
                        showTick: true,
                      },
                      {
                        limit: 20,
                        color: "#F5CD19",
                        showTick: true,
                      },
                      {
                        limit: 80,
                        color: "#5BE12C",
                        showTick: true,
                      },
                      {
                        limit: 100,
                        color: "#F5CD19",
                        showTick: true,
                      },
                      {
                        color: "#EA4228",
                      },
                    ],
                  }}
                  pointer={{
                    color: "#345243",
                    length: 0.8,
                    width: 15,
                  }}
                  labels={{
                    valueLabel: {
                      formatTextValue: (value) => value,
                      style: {
                        fill: "cyan",
                        textShadow: "none",
                        stroke: "CaptionText",
                        strokeWidth: 0.8,
                        fontWeight: "bolder",
                        fontSize: 44,
                        display: "none",
                      },
                    },
                    tickLabels: {
                      type: "outer",
                      valueConfig: {
                        formatTextValue: (value) => value + "ºC",
                        fontSize: 10,
                      },
                      ticks: [
                        { value: -50 },
                        { value: -40 },
                        { value: -30 },
                        { value: -20 },
                        { value: -10 },
                        { value: 0 },
                        { value: 10 },
                        { value: 20 },
                        { value: 30 },
                        { value: 40 },
                        { value: 50 },
                        { value: 60 },
                        { value: 70 },
                        { value: 80 },
                        { value: 90 },
                        { value: 100 },
                        { value: 110 },
                        { value: 120 },
                        { value: 130 },
                        { value: 140 },
                        { value: 150 },
                      ],
                      defaultTickValueConfig: {
                        style: { fill: "black" },
                      },
                    },
                  }}
                  value={k.value}
                  minValue={-50}
                  maxValue={150}
                />
                <p className="text-center text-2xl font-bold mt-0">{k.value}</p>
                <p className="text-center text-2xl font-bold mt-0">{k.title}</p>
              </div>
            );
          })}
        </div>
        <div className="mt-5">
          <div className="sm:flex sm:flex-row flex-col justify-around">
            {btnArr?.map((b) => {
              if (!b.value) {
                return <></>;
              }
              return (
                <div
                  className={`text-white text-xl fw-bolder mb-2 p-3 rounded-full text-center ${
                    b.value === "OFF" ? "bg-success" : "bg-danger"
                  }`}
                >
                  {b.title}{" "}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <ProgressSpinner className="w-full" />
      </div>
    </>
  );
};

export default LiveChartTab;
