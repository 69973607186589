import { createSlice } from "@reduxjs/toolkit";
import { updateCategoryAction } from "../../actions/categories/updateCategoryAction";
import { addCategoryAction } from "../../actions/categories/addCategoryAction";

const initialState = {
  data: [],
  // updatedData: [],
  loading: false,
  error: null,
};

const addCategorySlice = createSlice({
  name: "addCategory",
  initialState,
  reducers: {
    clearaddCategoryState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCategoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(addCategoryAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateCategoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCategoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateCategoryAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearaddCategoryState } = addCategorySlice.actions;
export default addCategorySlice.reducer;
