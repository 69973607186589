import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword } from "../../actions/forgotPassword/forgotPassword";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const forgotPasswordReducer = createSlice({
  name: "forgotPasswordReducer",
  initialState,
  reducers: {
    clearforgotPasswordReducer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearforgotPasswordReducer } = forgotPasswordReducer.actions;
export default forgotPasswordReducer.reducer;
