import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { myHeaders } from "../service";

export const tripList = createAsyncThunk(
  "admin/tripList",
  async (_, { rejectWithValue }) => {
    try {
      const { role, id } = JSON.parse(localStorage.getItem("userData"));
      const data = {
        userId: id,
        role: role,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API}/module-table/total-trip-device-list`,
        data,
        { headers: myHeaders() }
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.assign("/");
      }
      if (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);
