import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const location = useLocation();
  const [showSideBar, setShowSideBar] = useState(false);
  const [showSubTab, setShowSubTab] = useState({ tab1: false, tab2: false });

  const toggleMenuContent = () => {
    setShowSideBar((prev) => !prev);
  };

  const handleSubTab = (tabName) => {
    if (tabName === "tab1") {
      setShowSubTab((prev) => ({ ...prev, tab1: !prev.tab1 }));
    } else if (tabName === "tab2") {
      setShowSubTab((prev) => ({ ...prev, tab2: !prev.tab2 }));
    }
  };

  // Check if the current route is the login page
  const isLoginPage = location.pathname === "/";

  // Conditionally render the header and sidebar
  const renderHeaderAndSidebar = !isLoginPage && (
    <>
      <Navbar toggleMenuContent={toggleMenuContent} />
      <div className="container-fluid page-body-wrapper pl-0 pr-0">
        {showSideBar && (
          <Sidebar
            handleSubTab={handleSubTab}
            showSubTab={showSubTab}
            setShowSideBar={setShowSideBar}
          />
        )}
        {children}
      </div>
    </>
  );

  return isLoginPage ? <> {children} </> : <div>{renderHeaderAndSidebar}</div>;
};

export default Layout;
