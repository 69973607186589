import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onlineOfflineDevice } from "../../redux/actions/controlPanel/onlineOfflineDevice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";

const DeviceTabs = () => {
  const dispatch = useDispatch();
  const deviceData = useSelector((state) => state.onlineOfflineDeviceReducer);
  const [deviceList, setDeviceList] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    dispatch(onlineOfflineDevice());
  }, [dispatch]);

  useEffect(() => {
    if (deviceData?.data?.length) {
      setDeviceList(deviceData?.data);
    }
  }, [deviceData]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="form-control form-control-sm ml-1"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const switchBodyTemplate = (row) => {
    if (row?.status === "Online") {
      return <Tag severity="success">Online</Tag>;
    } else if (row?.status === "Temporary Offline") {
      return <Tag severity="warning">Temporary Offline</Tag>;
    } else if (row?.status === "Long term Offline") {
      return <Tag style={{ background: "gray" }}>Long Term Offline</Tag>;
    } else {
      return <Tag severity="danger">Offline</Tag>;
    }
  };

  return (
    <>
      {deviceList?.length && (
        <DataTable
          value={deviceList}
          filters={filters}
          removableSort
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          totalRecords={deviceList?.length}
          emptyMessage="No devices found."
          className="bg-none"
          showGridlines
        >
          <Column
            className="border-1 text-[14px] p-1 text-center"
            body={(_, { rowIndex }) => rowIndex + 1}
            header="Sr. No"
          ></Column>
          <Column
            className="border-1 text-[14px] p-1 text-center"
            field="node"
            header="Node"
            sortable
          ></Column>
          <Column
            className="border-1 text-[14px] p-1 text-center"
            field="tableName"
            header="Table"
            sortable
          ></Column>
          <Column
            className="border-1 text-[14px] p-1 text-center"
            field="moduleName"
            header="Module"
            sortable
          ></Column>
          <Column
            className="border-1 py-2 pl-[2rem] text-center"
            // body={switchBodyTemplate}
            field="status"
            header="Status"
          ></Column>
          <Column
            className="border-1 py-2 pl-[2rem] text-center"
            field="lastUpdated"
            header="Last Updated"
          ></Column>
          {/*<Column
          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
          body={actionBodyTemplate}
          header="Action"
        ></Column> */}
        </DataTable>
      )}
    </>
  );
};

export default DeviceTabs;
