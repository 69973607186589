import React, { useState } from "react";
import { controlPanelTabs } from "../../utils/data";
import { useNavigate } from "react-router-dom";
import DeviceTabs from "./DeviceTabs";
import ModulesTabs from "./ModulesTabs";
import AlarmsTabs from "./AlarmsTabs";
import TripsTabs from "./TripsTabs";

const ControlPanel = () => {
  const [activeTabID, setActiveTabId] = useState("1");

  // const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTabId(tab?.id);
    // navigate(`/control-panel/${tab?.value}`);
  };
  const tabs = [
    { id: "1", component: <DeviceTabs /> },
    { id: "2", component: <ModulesTabs /> },
    { id: "3", component: <AlarmsTabs /> },
    { id: "4", component: <TripsTabs /> },
  ];

  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper manageUser">
          <div className="container-fluid py-3">
            <div className="height-100">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-2">
                    <p className="text-2xl text-center">Control Panel</p>
                    <div className="row text-center mx-[5px]">
                      {controlPanelTabs?.map((t, idx) => (
                        <div
                          className={`border cursor-pointer py-2 col-12 col-md-3 ${
                            activeTabID === t?.id ? "bg-gray-400" : ""
                          }`}
                          key={`${idx}_${t?.id}`}
                          onClick={() => handleTabClick(t)}
                        >
                          <p>{t?.name}</p>
                        </div>
                      ))}
                    </div>
                    <div className="pt-3">
                      {tabs.map(
                        (tab) =>
                          activeTabID === tab.id && (
                            <div key={tab.id}>{tab.component}</div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ControlPanel;
