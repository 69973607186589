import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAction } from "../redux/actions/users/getUsersAction";
import { Link } from "react-router-dom";
import { alarmTripCount } from "../redux/actions/alarmTripCount/alarmTripCount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { onlineOfflineCount } from "../redux/actions/alarmTripCount/onlineOfflineCount";
import { transformData } from "../utils/generateRecords";
import { getUsersWithNode } from "../redux/actions/admin/getUsersWithNode";
import { totalAlarmCount } from "../redux/actions/alarmTripCount/totalAlarmCount";
import { onlineOfflineNode } from "../redux/actions/alarmTripCount/onlineOfflineNode";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import PieChart from "../commoncomponents/PieChart";
import UserDevice from "./UserDevice";
import { modifyKeysName } from "../utils/converData";
import { useAudio } from "../AudioProvider";

const Home = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [alarmTripData, setAlarmTripData] = useState(null);
  const [onlineOfflineData, setOnlineOfflineData] = useState(null);
  const [totalDevice, setTotalDevice] = useState(0);
  const [nodeTotal, setNodeTotal] = useState(0);
  const [alarmsChart, setAlarmsChart] = useState([]);
  const [tripsChart, setTripsChart] = useState([]);
  const [deviceChart, setDeviceChart] = useState([]);
  const [nodeData, setNodeData] = useState([]);
  const [onOfNode, setOnOFNode] = useState([]);
  const [nodeCharts, setNodeCharts] = useState([]);
  const { role, id } = JSON.parse(localStorage.getItem("userData")) || {};
  const getUsers = useSelector((state) => state.getUsers);
  const usersWithNode = useSelector((state) => state.getUsersWithNodeReducer);
  const alarmTripCountData = useSelector(
    (state) => state.alarmTripCountReducer
  );
  const onlineOfflineCountData = useSelector(
    (state) => state.onlineOfflineCountReducer
  );
  const onlineOfflineNodeData = useSelector(
    (state) => state.onlineOfflineNodeReducer
  );

  const { playAudio } = useAudio();

  useEffect(() => {
    const payload = {
      userId: id,
      role: role,
    };
    dispatch(onlineOfflineNode(payload));
    dispatch(alarmTripCount(payload));
    dispatch(onlineOfflineCount(payload));
    dispatch(totalAlarmCount(payload));
    if (role === 0) {
      dispatch(getUsersWithNode());
      dispatch(getUsersAction({ searchValue: "" }));
    }
  }, [dispatch, role, id]);

  useEffect(() => {
    if (
      onlineOfflineNodeData?.data &&
      Object.keys(onlineOfflineNodeData?.data)?.length > 0
    ) {
      const chartData = Object.entries(onlineOfflineNodeData?.data)?.map(
        ([key, value]) => ({
          name: modifyKeysName(key),
          y: value,
          color:
            key === "onlineDevice"
              ? "#5BE12C"
              : key === "TemporaryOffline"
              ? "#F5CD19"
              : key === "LongTermOffline"
              ? "#A9A9A9"
              : "#EA4228",
        })
      );
      setNodeCharts(chartData);
    }
  }, [onlineOfflineNodeData]);

  useEffect(() => {
    if (getUsers?.data?.data?.length > 0) {
      setUsers([
        ...getUsers.data.data?.filter((i) => !i?.isActive),
        ...getUsers.data.data?.filter((i) => i?.isActive),
      ]);
    }
  }, [getUsers]);

  useEffect(() => {
    if (usersWithNode?.data?.data?.length > 0) {
      const newData = transformData(usersWithNode.data.data);
      setNodeData(newData);
    }
  }, [usersWithNode]);

  useEffect(() => {
    if (
      onlineOfflineNodeData?.data &&
      Object.keys(onlineOfflineNodeData?.data)?.length > 0
    ) {
      const { onlineDevice, offlineDevice, TemporaryOffline, LongTermOffline } =
        onlineOfflineNodeData?.data;
      setOnOFNode(onlineOfflineNodeData?.data);
      setNodeTotal(
        onlineDevice + offlineDevice + TemporaryOffline + LongTermOffline
      );
    }
  }, [onlineOfflineNodeData]);

  useEffect(() => {
    if (
      alarmTripCountData?.data?.data &&
      Object.keys(alarmTripCountData?.data?.data)?.length > 0
    ) {
      setAlarmTripData(alarmTripCountData?.data?.data);
      const alarmsData = Object.entries(alarmTripCountData?.data?.data)
        .filter(
          ([key]) =>
            key === "totalResolveAlarm" ||
            key === "unacknowledgedAlarm" ||
            key === "acknowledgedAlarm"
        )
        .map(([key, value]) => ({
          name:
            key === "totalResolveAlarm"
              ? "Resolved"
              : key === "unacknowledgedAlarm"
              ? "Unacknowledged"
              : "Acknowledged",
          y: value,
          color:
            key === "totalResolveAlarm"
              ? "#5BE12C"
              : key === "unacknowledgedAlarm"
              ? "#EA4228"
              : "#F5CD19",
          // color: key === "totalResolveAlarm" ? "#5BE12C" : "#F5CD19",
        }));
      setAlarmsChart(alarmsData);
      const tripsData = Object.entries(alarmTripCountData?.data?.data)
        .filter(
          ([key]) =>
            key === "totalResolveTrip" ||
            key === "unacknowledgedTrip" ||
            key === "acknowledgedTrip"
        )
        .map(([key, value]) => ({
          name:
            key === "totalResolveTrip"
              ? "Resolved"
              : key === "unacknowledgedTrip"
              ? "Unacknowledged"
              : "Acknowledged",
          y: value,
          color:
            key === "totalResolveTrip"
              ? "#5BE12C"
              : key === "unacknowledgedTrip"
              ? "#EA4228"
              : "#F5CD19",
        }));
      setTripsChart(tripsData);
    }
  }, [alarmTripCountData]);

  useEffect(() => {
    const { unacknowledgedAlarm, unacknowledgedTrip } =
      alarmTripCountData?.data?.data || {};

    if (unacknowledgedAlarm >= 1 || unacknowledgedTrip >= 1) {
      playAudio();
    }
  }, [alarmTripCountData]);

  useEffect(() => {
    if (
      onlineOfflineCountData?.data &&
      Object.keys(onlineOfflineCountData?.data)?.length > 0
    ) {
      const chartData = Object.entries(onlineOfflineCountData?.data)?.map(
        ([key, value]) => ({
          name: modifyKeysName(key),
          y: value,
          color:
            key === "onlineDevice"
              ? "#5BE12C"
              : key === "TemporaryOffline"
              ? "#F5CD19"
              : key === "LongTermOffline"
              ? "#A9A9A9"
              : "#EA4228",
        })
      );
      setDeviceChart(chartData);
      setOnlineOfflineData(onlineOfflineCountData?.data);
      const { onlineDevice, TemporaryOffline, LongTermOffline, offlineDevice } =
        onlineOfflineCountData?.data || {};
      setTotalDevice(
        onlineDevice + TemporaryOffline + LongTermOffline + offlineDevice
      );
    }
  }, [onlineOfflineCountData]);

  const statusColumnBody = (row) => {
    if (row?.isActive) {
      return <span className="p-tag p-tag-success">Active</span>;
    } else {
      return <span className="p-tag p-tag-danger">In Active</span>;
    }
  };

  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper">
          <div className="container-fluid py-2">
            <div className="height-100">
              {
                <>
                  <div className="row">
                    <div className="col-sm-3 col-md-3 col-lg-3 sm:mb-0 mb-2">
                      <div className="card !bg-cyan-300 space h-[76px] rounded-xl">
                        <div className="card-body rounded ">
                          <h6 className="card-title">Total Online Device</h6>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <p className="card-para">
                                {onOfNode?.onlineDevice}/{nodeTotal}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 sm:mb-0 mb-2">
                      <div className="card bg-2 space h-[76px] rounded-xl">
                        <div className="card-body rounded ">
                          <h6 className="card-title">Total Online Modules</h6>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <p className="card-para">
                                {onlineOfflineData?.onlineDevice}/{totalDevice}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 sm:mb-0 mb-2">
                      <div className="card bg-1 space h-[76px] rounded-xl">
                        <div className="card-body rounded ">
                          <div className="flex justify-between">
                            <h6 className="card-title">Total Alarms</h6>
                          </div>
                          <div className="flex justify-between">
                            <p className="card-para">
                              {alarmTripData?.totalAlarm}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3 col-md-3 col-lg-3">
                      <div className="card bg-3 space h-[76px] rounded-xl">
                        <div className="card-body rounded ">
                          <div className="flex justify-between">
                            <h6 className="card-title">Total Trip</h6>
                          </div>
                          <div className="flex justify-between">
                            <p className="card-para">
                              {alarmTripData?.totalTrip}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <marquee behavior="" direction="">
                        <div className="flex">
                          <div className="flex items-center mx-1 text-slate-600">
                            <p className="p-1 !h-[2px] !w-[2px] mx-1 bg-[#5BE12C] rounded" />{" "}
                            Online{" "}
                          </div>
                          <div className="flex items-center mx-1 text-slate-600">
                            {" "}
                            <p className="p-1 !h-[2px] !w-[2px] mx-1 bg-[#F5CD19] rounded" />{" "}
                            Temporary Offline (Below 1 Hour)
                          </div>
                          <div className="flex items-center mx-1 text-slate-600">
                            {" "}
                            <p className="p-1 !h-[2px] !w-[2px] mx-1 bg-[#EA4228] rounded" />{" "}
                            Offline ( 2 - 24 Hours)
                          </div>
                          <div className="flex items-center mx-1 text-slate-600">
                            {" "}
                            <p className="p-1 !h-[2px] !w-[2px] mx-1 bg-[#A9A9A9] rounded" />
                            Long Term Offline (More Than 24 Hours)
                          </div>
                        </div>
                      </marquee>
                    </div>
                    <div className="col-3">
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faInfoCircle} /> Last 30 Days
                        Data
                      </p>
                    </div>
                  </div>
                  <div className="row h-1/2">
                    <div className="col-12 col-md-3 home-pie">
                      <PieChart
                        isLoading={alarmTripCountData?.loading}
                        data={nodeCharts}
                        title="Total Devices"
                      />
                    </div>
                    <div className="col-12 col-md-3 home-pie">
                      <PieChart
                        data={deviceChart}
                        isLoading={onlineOfflineCountData?.loading}
                        title="Total Modules"
                      />
                    </div>
                    <div className="col-12 col-md-3 home-pie">
                      <PieChart
                        isLoading={alarmTripCountData?.loading}
                        data={alarmsChart}
                        title="Alarms"
                      />
                    </div>

                    <div className="col-12 col-md-3 home-pie">
                      <PieChart
                        isLoading={alarmTripCountData?.loading}
                        data={tripsChart}
                        title="Trips"
                      />
                    </div>
                  </div>
                </>
              }
              {role === 0 && (
                <div className="row py-2">
                  {/* Users */}
                  <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                    <div className="card rounded mb-3 h-100">
                      <div className="card-title ">
                        <div className="row">
                          <div className=" col-md-6 col-lg-6 col-6 ">
                            <div>
                              <p>
                                <b>User</b>{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 col-6  text-end">
                            <div className="Heading ">
                              <Link to={"/manageUser"} className="text-primary">
                                <b> All Users</b>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <DataTable
                          value={users}
                          // header={renderHeaderUser()}
                          // filters={filtersUsers}
                          removableSort
                          paginator={!!users.length}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 20]}
                          totalRecords={users.length}
                          emptyMessage="No users found."
                          showGridlines
                        >
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            header="Sr No"
                            body={(_, { rowIndex }) => rowIndex + 1}
                          ></Column>
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            field="first_name"
                            header="User Name"
                            sortable
                          ></Column>
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            header="Status"
                            body={statusColumnBody}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                  {/* Node */}
                  <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                    <div className="card rounded mb-3 h-100">
                      <div className="card-title ">
                        <div className="row">
                          <div className=" col-md-6 col-lg-6 col-6 ">
                            <div>
                              <p>
                                <b>Node</b>{" "}
                              </p>
                            </div>
                          </div>
                          <div className=" col-md-6 col-lg-6 col-6  text-end">
                            <div className="Heading ">
                              <Link
                                className="text-primary"
                                to={"/device-list"}
                              >
                                <b>All Nodes</b>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <DataTable
                          // header={header}
                          // filters={filters}
                          value={nodeData}
                          removableSort
                          paginator={!!nodeData.length}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 20]}
                          totalRecords={nodeData.length}
                          emptyMessage="No Device found."
                          showGridlines
                        >
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            header="Sr No"
                            body={(_, { rowIndex }) => rowIndex + 1}
                          ></Column>
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            field="node_name"
                            header="Node Name"
                            sortable
                          ></Column>
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            field="description"
                            header="Description"
                            sortable
                          ></Column>
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            field="site_name"
                            header="Site Name"
                            sortable
                          ></Column>
                          <Column
                            className="border-1 text-[14px] p-1 text-center"
                            header="Status"
                            body={statusColumnBody}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {role !== 0 && (
                <div className="w-full bg-white">
                  <UserDevice />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
