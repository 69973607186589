import validator from "validator";

export const assignedEventValidation = (data) => {
  const error = {};
  if (validator.isEmpty(data.product_id)) {
    error.product_id = "Please select node";
  }
  if (data.events.length === 0) {
    error.events = "Please select event";
  }
  return error;
};
