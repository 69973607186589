import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardData from "./Admin/CardData";
import SubTab from "../commoncomponents/SubTab";
import GraphViewTab from "./Admin/GraphViewTab";
import { accessEvents } from "../redux/actions/events/accessEvents";
import { accessModule } from "../redux/actions/accessnodes/accessModule";
import { getProductAction } from "../redux/actions/product/getProductAction";
import Loading from "../commoncomponents/Loading";
import { ProgressSpinner } from "primereact/progressspinner";

const AllUsers = () => {
  const dispatch = useDispatch();
  const { role } = JSON.parse(localStorage.getItem("userData"));

  const [activeTab, setActiveTab] = useState(1);
  const [moduleList, setModuleList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const modulesList = useSelector((state) => state.accessModule);
  const accessEventsData = useSelector((state) => state.accessEvents);
  const location = useParams();

  useEffect(() => {
    dispatch(getProductAction({ value: "" }));
    const data = { product_id: location.id, user_id: location.userId };
    dispatch(accessModule(data));
    dispatch(accessEvents(data));
  }, [dispatch, location]);

  useEffect(() => {
    if (modulesList?.data?.data?.length > 0) {
      setModuleList(modulesList.data.data);
    }
  }, [modulesList]);

  useEffect(() => {
    if (accessEventsData?.data?.data?.length > 0) {
      setEventList(accessEventsData.data.data);
    }
  }, [accessEventsData]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div className="main-panel">
        <div className="container-wrapper manageUser">
          <div className="container-fluid py-3 px-3">
            <div className="height-100 bg">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card caption-top ">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-12 col-md-6">
                          {role === 0 && moduleList?.[0]?.user_name && (
                            <span className="text-[#495057] !text-[16px]">
                              User: {moduleList?.[0]?.user_name}
                            </span>
                          )}
                        </div>
                        <div className="col-12 col-md-6 md:text-end">
                          Node:{" "}
                          {modulesList?.loading ? (
                            <ProgressSpinner className="w-[15px] h-[15px]" />
                          ) : (
                            <span className="text-[#495057] !text-[16px]">
                              {moduleList?.[0]?.node_name}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Nav tabs */}
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <p
                            className={`nav-link cursor-pointer ${
                              activeTab === 1 ? "active" : ""
                            }`}
                            onClick={() => handleTabClick(1)}
                            data-toggle="tab"
                          >
                            Live Readings
                          </p>
                        </li>
                        <li className="nav-item">
                          <p
                            className={`nav-link cursor-pointer ${
                              activeTab === 2 ? "active" : ""
                            }`}
                            onClick={() => handleTabClick(2)}
                            data-toggle="tab"
                          >
                            Events
                          </p>
                        </li>
                        <li className="nav-item">
                          <p
                            className={`nav-link cursor-pointer ${
                              activeTab === 3 ? "active" : ""
                            }`}
                            onClick={() => handleTabClick(3)}
                            data-toggle="tab"
                          >
                            Tabular View
                          </p>
                        </li>
                        <li className="nav-item">
                          <p
                            className={`nav-link cursor-pointer ${
                              activeTab === 4 ? "active" : ""
                            }`}
                            onClick={() => handleTabClick(4)}
                            data-toggle="tab"
                          >
                            Graph View
                          </p>
                        </li>
                        {/* <li className="nav-item absolute right-2 md:top-8 text-[#495057] !text-[16px] top-14">
                          <span className="hidden md:inline">
                            Node name: {moduleList?.[0]?.node_name}
                          </span>
                        </li> */}
                        {/* <li className="nav-item absolute right-2 md:top-auto top-14">
                          {role === 0 && (
                            <button
                              className="btn btn-outline-secondary mr-1"
                              onClick={() => {
                                navigate("/device-list");
                                dispatch(clearaccessModuleReducer());
                                dispatch(cleargetChartDataReducer());
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faList}
                                className="md:mr-2 mr-0"
                              />
                              <span className="hidden md:inline">
                                Device Lists
                              </span>
                            </button>
                          )}
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              navigate("/home");
                              dispatch(clearaccessModuleReducer());
                              dispatch(cleargetChartDataReducer());
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTachometerAlt}
                              className="md:mr-2 mr-0"
                            />
                            <span className="hidden md:inline">DashBoard</span>
                          </button>
                        </li> */}
                      </ul>
                      <div className="tab-content">
                        <div
                          id="home"
                          className={` tab-pane ${
                            activeTab === 3 ? "active" : "fade"
                          }`}
                        >
                          {!!moduleList.length && activeTab === 3 ? (
                            <SubTab
                              tabList={moduleList}
                              activeParentTab={activeTab}
                            />
                          ) : (
                            <p className="text-center">No data available</p>
                          )}
                        </div>
                        <div
                          id="menu1"
                          className={`tab-pane ${
                            activeTab === 2 ? "active" : "fade"
                          }`}
                        >
                          {!!eventList.length && activeTab === 2 ? (
                            <SubTab
                              tabList={eventList}
                              activeParentTab={activeTab}
                            />
                          ) : (
                            <p className="text-center">No data available</p>
                          )}
                        </div>
                        <div
                          id="menu2"
                          className={` tab-pane ${
                            activeTab === 1 ? "active" : "fade"
                          }`}
                        >
                          {!!moduleList.length && activeTab === 1 ? (
                            <CardData nodeId={location.id} />
                          ) : (
                            <p className="text-center">No data available</p>
                          )}
                        </div>
                        <div
                          id="menu2"
                          className={`tab-pane ${
                            activeTab === 4 ? "active" : "fade"
                          }`}
                        >
                          {activeTab === 4 ? (
                            <GraphViewTab
                              tabList={moduleList}
                              nId={location.id}
                              activeParentTab={activeTab}
                            />
                          ) : (
                            <p className="text-center">No data available</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUsers;
