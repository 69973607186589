import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("auth-token")
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "auth-token") {
        if (event.newValue !== null) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return isAuthenticated ? <Navigate to="/home" /> : <Outlet />;
};

export default PublicRoute;
