import validator from "validator";

export const addProductValidation = (data) => {
  const error = {};
  if (validator.isEmpty(data?.name)) {
    error.name = "Please enter node name";
  }
  if (validator.isEmpty(data?.longitude)) {
    error.longitude = "Please enter longitude";
  }
  if (validator.isEmpty(data?.latitude)) {
    error.latitude = "Please enter latitude";
  }
  if (validator.isEmpty(data?.description)) {
    error.description = "Please enter description";
  }
  if (validator.isEmpty(data?.site_name)) {
    error.site_name = "Please enter site name";
  }
  if (validator.isEmpty(data?.site_area_location)) {
    error.site_area_location = "Please enter Site Area Location";
  }
  if (validator.isEmpty(data?.remarks)) {
    error.remarks = "Please enter remarks";
  }

  return error;
};
