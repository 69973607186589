import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleargetDataByDateReducer } from "../../redux/reducers/admin/getDataByDateReducer";
import { getChartData } from "../../redux/actions/admin/getChartData";
import GaugeComponent from "react-gauge-component";
import { graphCTPTicks, graphTicks, standardTicks } from "../../utils/data";
import moment from "moment";
import NodeWiseGuageChart from "../../commoncomponents/NodeWiseGuageChart";

const useResizeObserver = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      callback(element.offsetWidth);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, [ref, callback]);

  return ref;
};

const CardData = ({ nodeId }) => {
  const dispatch = useDispatch();
  const chartData = useSelector((state) => state?.getChartDataReducer);
  const [containerWidth, setContainerWidth] = useState(0);
  const guageContainerRef = useResizeObserver((width) => {
    setContainerWidth(width);
  });

  const generateKey = useCallback(
    (baseKey, width) => `${baseKey}_${width}`,
    []
  );

  const [data, setData] = useState(null);
  const moveItemsToFirstPosition = (dataArray, targetName) => {
    const targetItems = dataArray.filter((item) => item.name === targetName);
    const otherItems = dataArray.filter((item) => item.name !== targetName);

    return [...targetItems, ...otherItems];
  };

  useEffect(() => {
    dispatch(cleargetDataByDateReducer());
    dispatch(getChartData(nodeId));

    const interval = setInterval(() => {
      dispatch(getChartData(nodeId));
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch, nodeId]);

  useEffect(() => {
    if (chartData?.data?.data?.length > 0) {
      const sortedData = moveItemsToFirstPosition(
        chartData?.data?.data,
        "TPR501RC"
      );
      setData(sortedData);
    }
  }, [chartData]);

  const keys = [
    { id: "OTI", value: "OTI" },
    { id: "WTI", value: "WTI" },
    { id: "WTI2", value: "WTI2" },
    { id: "WTI3", value: "WTI3" },
    { id: "CTP", value: "CT-Percentage" },
    { id: "CT2P", value: "CT2-Percentage" },
    { id: "CT3P", value: "CT3-Percentage" },
    { id: "OTI-MAX", value: "OTI" },
    { id: "WTI-MAX", value: "WTI" },
    { id: "WT2I-MAX", value: "WT2I" },
  ];
  const tpr104RCKeys = [
    { id: "CH1", value: "CH1" },
    { id: "CH2", value: "CH2" },
    { id: "CH3", value: "CH3" },
    { id: "CH4", value: "CH4" },
    { id: "CH5", value: "CH5" },
    { id: "CH6", value: "CH6" },
    { id: "CH7", value: "CH7" },
    { id: "CH8", value: "CH8" },
  ];
  const btnKeys = [
    { id: "RLYOTA", value: "OT - Alarm" },
    { id: "RLYOTT", value: "OT - Trip" },
    { id: "RLYWTA", value: "WT - Alarm" },
    { id: "RLYWTT", value: "WT - Trip" },
    { id: "RLYWT2A", value: "WT2 - Alarm" },
    { id: "RLYWT2T", value: "WT2 - Trip" },
    { id: "RLYWT3A", value: "WT3 - Alarm" },
    { id: "RLYWT3T", value: "WT3 - Trip" },
    { id: "RLY1", value: "Sensor Fault" },
    { id: "RLY2", value: "Alarm" },
    { id: "RLY3", value: "Trip" },
    { id: "RLY4", value: "Fan" },
  ];
  const blocksData = [
    { id: "RPhaseNeutralVoltage", value: "R-N Volt", color: "pink" },
    { id: "YPhaseNeutralVoltage", value: "Y-N Volt", color: "yellow" },
    { id: "BPhaseNeutralVoltage", value: "B-N Volt", color: "blue" },
    { id: "FrequencyHz", value: "Frequency", color: "white" },
    { id: "RYVoltage", value: "R-Y Volt", color: "white" },
    { id: "YBVoltage", value: "Y-B Volt", color: "white" },
    { id: "BRVoltage", value: "B-R Volt", color: "white" },
    { id: "AverageVoltage", value: "Avg. Voltage", color: "white" },
    { id: "RPhaseLineCurrent", value: "R Phase Amp.", color: "pink" },
    { id: "YPhaseLineCurrent", value: "Y Phase Amp.", color: "yellow" },
    { id: "BPhaseLineCurrent", value: "B Phase Amp.", color: "blue" },
    { id: "NeutralLineCurrent", value: "N Phase Amp.", color: "white" },
    { id: "Q1PowerFactor", value: "Q1 PF", color: "white" },
    { id: "Q2PowerFactor", value: "Q2 PF", color: "white" },
    { id: "Q3PowerFactor", value: "Q3 PF", color: "white" },
    { id: "AvgPowerFactor", value: "Avg. PF", color: "white" },
    { id: "RPhaseActivePower", value: "R Phase W", color: "pink" },
    { id: "YPhaseActivePower", value: "Y Phase W", color: "yellow" },
    { id: "BPhaseActivePower", value: "B Phase W", color: "blue" },
    { id: "ThreePhaseActivePower", value: "3 Phase W", color: "white" },
    { id: "RPhaseReaivePower", value: "R Phase VAr", color: "pink" },
    { id: "YPhaseReaivePower", value: "Y Phase VAr", color: "yellow" },
    { id: "BPhaseReaivePower", value: "B Phase VAr", color: "blue" },
    { id: "ThreePhaseReaivePower", value: "3 Phase VAr", color: "white" },
    { id: "RPhaseApparentPower", value: "R Phase VA", color: "pink" },
    { id: "YPhaseApparentPower", value: "Y Phase VA", color: "yellow" },
    { id: "BPhaseApparentPower", value: "B Phase VA", color: "blue" },
    { id: "ThreePhaseApparentPower", value: "3 Phase VA", color: "white" },
  ];
  const irBlocksData = [
    { id: "Relay1", value: "Buchholz Alarm" },
    { id: "Relay2", value: "Buchholz Trip" },
    { id: "Relay3", value: "MOG Alarm" },
    { id: "Relay4", value: "OLTC/TANK PRV Trip" },
    { id: "Relay5", value: "OLTC OSR Trip" },
  ];

  function updateBlocksData(data, blocks) {
    return blocks.map((block) => {
      if (data.hasOwnProperty(block.id)) {
        return { ...block, actualValue: data[block.id] };
      }
      return block;
    });
  }

  let guageArr = [];
  let irBlockArr = [];
  let tipData = 0;
  let btnArr = [];

  if (chartData?.data?.data?.length === 0)
    return <div className="text-center">No Data Found</div>;

  chartData?.data?.data?.forEach((d) => {
    if (d?.name === "TPR501RC") {
      guageArr = keys.map((key) => {
        return {
          title: key.value,
          value: d?.[key.id],
        };
      });
      btnArr = btnKeys.map(({ id, value }) => ({
        title: value,
        value: d?.[id],
      }));
    }
    if (d?.name === "IR101C") {
      irBlockArr = irBlocksData?.map(({ id, value }) => ({
        title: value,
        value: d?.[id],
      }));
    }
    if (d?.name === "TPI540RC") {
      tipData = d?.TapPosition;
    }
  });

  const processData = (dataItem, keys, btnKeys) => {
    if (!dataItem) {
      return { gaugeArr: [], btnArr: [] };
    }

    const gaugeArr = keys.map((key) => ({
      title: key.value,
      value: dataItem?.[key.id],
    }));

    const btnArr = btnKeys.map(({ id, value }) => ({
      title: value,
      value: dataItem?.[id],
    }));

    return { gaugeArr, btnArr };
  };

  return (
    <>
      <div className="row mt-0" ref={guageContainerRef}>
        {data?.some((d) => d?.name === "TPR501RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR501RC") {
              return (
                <div
                  key={`${idxe}_${d?.DateTime}_#`}
                  className="col-12 col-md-6 mb-3"
                >
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      {
                        <div className="row">
                          {guageArr?.every((i) => i?.value === undefined) ? (
                            <p className="text-center font-bold text-slate-500">
                              Device is Offline
                            </p>
                          ) : (
                            guageArr?.slice(0, 2)?.map((k, index) => {
                              if (!k?.value && k?.value !== 0) {
                                return <></>;
                              }
                              if (k?.value === 555) {
                                return (
                                  <div
                                    className="col-12 col-md-6 text-center"
                                    key={`${k?.value}_${index}_#`}
                                  >
                                    <p className="flex justify-center">
                                      <img
                                        src={"/alert.png"}
                                        alt="fault"
                                        width={100}
                                      />
                                    </p>
                                    <p className="font-bold text-red-600 text-2xl">
                                      Sensor Fault
                                    </p>
                                    <p className="font-bold text-xl">
                                      {k?.title}
                                    </p>
                                  </div>
                                );
                              }
                              return (
                                <NodeWiseGuageChart
                                  parentDivKey={`${k?.value}_${index}_$#${index}`}
                                  guageKey={generateKey(
                                    `${d.name}_${idxe}`,
                                    containerWidth
                                  )}
                                  guageTitle={k?.title}
                                  guageValue={k?.value}
                                  maxValue={150}
                                  nodeName={"TPR501RC"}
                                />
                              );
                            })
                          )}
                          {guageArr?.slice(2, -1)?.map((k, index) => {
                            if (!k?.value && k?.value !== 0) {
                              return <></>;
                            }
                            if (k?.value === 555) {
                              return (
                                <div
                                  className="col-12 col-md-6 text-center"
                                  key={`${k?.value}_${index}`}
                                >
                                  <p className="flex justify-center">
                                    <img
                                      src={"/alert.png"}
                                      alt="fault"
                                      width={100}
                                    />
                                  </p>
                                  <p className="font-bold text-red-600 text-2xl">
                                    Sensor Fault
                                  </p>
                                  <p className="font-bold text-xl">
                                    {k?.title}
                                  </p>
                                </div>
                              );
                            }
                            return (
                              <>
                                <div className="col-3"></div>
                                <NodeWiseGuageChart
                                  parentDivKey={`${k?.value}_${index}`}
                                  guageKey={generateKey(
                                    `${d.name}_${idxe}`,
                                    containerWidth
                                  )}
                                  guageTitle={k?.title}
                                  guageValue={k?.value}
                                  maxValue={150}
                                  nodeName={"TPR501RC"}
                                />
                                <div className="col-3"></div>
                              </>
                            );
                          })}
                        </div>
                      }
                      <div className="mt-3">
                        {!btnArr?.every((i) => i?.value === undefined) && (
                          <>
                            <h1 className="text-center text-2xl text-bolder mb-2">
                              Alarm/Trip Indication
                            </h1>
                            <div className="sm:flex sm:flex-row flex-col justify-around row">
                              {btnArr?.map((b, idxq) => {
                                if (!b.value) {
                                  return null;
                                }
                                return (
                                  <div
                                    key={`${idxq}_#${b.title}`}
                                    className={`text-black font-bold text-sm sm:mt-0 mt-2  py-2 rounded-full text-center col-sm-2 col-12 ${
                                      b.value === "OFF"
                                        ? "bg-[#5BE12C]"
                                        : "bg-[#EA4228]"
                                    }`}
                                  >
                                    {b.title}
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "ELITE300") &&
          data?.map((d, idxe) => {
            if (d?.name === "ELITE300") {
              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {updateBlocksData(d, blocksData)?.every(
                          (i) => i?.actualValue === undefined
                        ) ? (
                          <p className="text-center font-bold text-slate-500">
                            Device is Offline
                          </p>
                        ) : (
                          updateBlocksData(d, blocksData)?.map((i, idx) => (
                            <div
                              className="col-12 col-sm-3 my-2"
                              key={`${i?.value}_${idx}`}
                            >
                              <div
                                className={`mx-1  font-semibold rounded-xl text-center p-2
                ${i?.color === "pink" && "bg-pink-200"}
                ${i?.color === "yellow" && "bg-yellow-300"}
                ${i?.color === "blue" && "bg-cyan-300"}
                ${i?.color === "white" && "bg-yellow-100"}`}
                              >
                                <div className="text-[14px]">{i?.value}</div>
                                <div className="text-primary text-[18px] font-bold">
                                  {i?.actualValue}
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "IR101C") &&
          data?.map((d, idxe) => {
            if (d?.name === "IR101C") {
              return (
                <div className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {
                      <div className="container pt-4">
                        <div className="row">
                          <div className="col-12 col-md-3"></div>
                          {irBlockArr?.every((i) => i?.value === undefined) ? (
                            <p className="text-center font-bold text-slate-500">
                              Device is Offline
                            </p>
                          ) : (
                            irBlockArr?.slice(0, 2)?.map((b, idxq) => {
                              if (!b.value) {
                                return null;
                              }
                              return (
                                <>
                                  <div
                                    key={`${idxq}_#${b.title}`}
                                    className={`text-black text-sm  mt-2  font-bold  py-2 rounded-full text-center col-12 col-md-3 md:mx-1 ${
                                      b.value === "OFF"
                                        ? "bg-[#5BE12C]"
                                        : "bg-[#ea4228]"
                                    }`}
                                  >
                                    {b.title}
                                  </div>
                                </>
                              );
                            })
                          )}
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-1"></div>
                          {irBlockArr?.slice(2, 6)?.map((b, idxq) => {
                            if (!b.value) {
                              return null;
                            }
                            return (
                              <>
                                <div
                                  key={`${idxq}_#${b.title}`}
                                  className={`text-black mt-2 text-sm font-bold  py-2 rounded-full text-center col-12 col-md-3 md:mx-1 ${
                                    b.value === "OFF"
                                      ? "bg-[#5BE12C]"
                                      : "bg-[#ea4228]"
                                  }`}
                                >
                                  {b.title}
                                </div>
                              </>
                            );
                          })}
                          <div className="col-2"></div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPI540RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPI540RC") {
              return (
                <div className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3"></div>
                        <div className="col-12 col-md-6 text-center">
                          {tipData ? (
                            tipData >= 88 ? (
                              <>
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{d?.name}</p>
                              </>
                            ) : (
                              <>
                                <NodeWiseGuageChart
                                  parentDivKey={""}
                                  guageKey={generateKey(
                                    `${d.name}_${idxe}`,
                                    containerWidth
                                  )}
                                  guageTitle={"TAP Position"}
                                  guageValue={d.TapPosition}
                                  maxValue={45}
                                  nodeName={"TPI540RC"}
                                />
                              </>
                            )
                          ) : (
                            <p className="text-center font-bold text-slate-500">
                              Device is Offline
                            </p>
                          )}
                        </div>
                        <div className="col-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPR104RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR104RC") {
              const { gaugeArr, btnArr } = processData(
                d,
                tpr104RCKeys,
                btnKeys
              );
              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {gaugeArr?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <NodeWiseGuageChart
                              parentDivKey={`${k?.value}_${index}`}
                              guageKey={generateKey(
                                `${d.name}_${idxe}`,
                                containerWidth
                              )}
                              guageTitle={k?.title}
                              guageValue={k?.value}
                              maxValue={300}
                              nodeName={"TPR104RC"}
                            />
                          );
                        })}
                      </div>
                      {btnArr?.every((i) => i?.value === undefined) ? (
                        <div>
                          <p className="text-center">No data found</p>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <h1 className="text-center text-2xl text-bolder mb-2">
                            Alarm/Trip Indication
                          </h1>
                          <div className="sm:flex sm:flex-row flex-col justify-around row">
                            {btnArr?.map((b, idxq) => {
                              if (!b.value) {
                                return null;
                              }
                              return (
                                <div
                                  key={`${idxq}_#${b.title}`}
                                  className={`text-black border-2 border-slate-700 py-2 rounded-full text-center col-12 col-sm-2 md:mt-0 mt-2 ${
                                    b.value === "OFF"
                                      ? "bg-[#5BE12C]"
                                      : "bg-[#ea4228]"
                                  }`}
                                >
                                  {b.title}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPR702RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR702RC") {
              const { gaugeArr, btnArr } = processData(d, keys, btnKeys);
              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {gaugeArr?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <NodeWiseGuageChart
                              parentDivKey={`${k?.value}_${index}`}
                              guageKey={generateKey(
                                `${d.name}_${idxe}`,
                                containerWidth
                              )}
                              guageTitle={k?.title}
                              guageValue={k?.value}
                              maxValue={150}
                              nodeName={"TPR104RC"}
                            />
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <h1 className="text-center text-2xl text-bolder mb-2">
                          Alarm/Trip Indication
                        </h1>
                        <div className="sm:flex sm:flex-row flex-col justify-around row">
                          {btnArr?.map((b, idxq) => {
                            if (!b.value) {
                              return null;
                            }
                            return (
                              <div
                                key={`${idxq}_#${b.title}`}
                                className={`text-black border-2 border-slate-700 py-2 rounded-full text-center col-12 col-sm-3 m-1 ${
                                  b.value === "OFF"
                                    ? "bg-[#5BE12C]"
                                    : "bg-[#ea4228]"
                                }`}
                              >
                                {b.title}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPR701RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR701RC") {
              const { gaugeArr, btnArr } = processData(d, keys, btnKeys);
              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {gaugeArr?.slice(0, 2)?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="col-12 col-md-6"
                              key={`${k?.value}_${index}`}
                            >
                              <GaugeComponent
                                key={generateKey(
                                  `${d.name}_${idxe}`,
                                  containerWidth
                                )}
                                type="semicircle"
                                arc={{
                                  width: 0.2,
                                  padding: 0.005,
                                  cornerRadius: 1,
                                  subArcs: [
                                    {
                                      limit: 90,
                                      color: "#5BE12C",
                                      showTick: true,
                                    },
                                    {
                                      limit: 110,
                                      color: "#F5CD19",
                                    },
                                    {
                                      color: "#EA4228",
                                    },
                                  ],
                                }}
                                pointer={{
                                  color: "#345243",
                                  length: 0.8,
                                  width: 15,
                                }}
                                labels={{
                                  valueLabel: {
                                    formatTextValue: (value) => value,
                                    style: {
                                      fill: "cyan",
                                      textShadow: "none",
                                      stroke: "CaptionText",
                                      strokeWidth: 0.8,
                                      fontWeight: "bolder",
                                      fontSize: 44,
                                      display: "none",
                                    },
                                  },
                                  tickLabels: {
                                    type: "outer",
                                    valueConfig: {
                                      formatTextValue: (value) => value + "ºC",
                                      fontSize: 10,
                                    },
                                    ticks:
                                      k?.title === "CT2-Percentage" ||
                                      k?.title === "CT2P"
                                        ? graphCTPTicks
                                        : graphTicks,
                                    defaultTickValueConfig: {
                                      style: { fill: "black" },
                                    },
                                  },
                                }}
                                value={k?.value}
                                minValue={
                                  k?.title === "CT2-Percentage" ||
                                  k?.title === "CT2P"
                                    ? 0
                                    : -50
                                }
                                maxValue={150}
                              />
                              <p className="text-center text-2xl font-bold mt-0 text-primary">
                                {k?.value}
                              </p>
                              <p className="text-center text-2xl font-bold mt-0">
                                {k.title}
                              </p>
                            </div>
                          );
                        })}
                        {gaugeArr?.slice(2, -1)?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <>
                              <div className="col-3"></div>
                              <div
                                className="col-12 col-md-6"
                                key={`${k?.value}_${index}`}
                              >
                                <div>
                                  <GaugeComponent
                                    key={generateKey(
                                      `${d.name}_${idxe}`,
                                      containerWidth
                                    )}
                                    type="semicircle"
                                    arc={{
                                      width: 0.2,
                                      padding: 0.005,
                                      cornerRadius: 1,
                                      subArcs: [
                                        {
                                          limit: 90,
                                          color: "#5BE12C",
                                          showTick: true,
                                        },
                                        {
                                          limit: 110,
                                          color: "#F5CD19",
                                        },
                                        {
                                          color: "#EA4228",
                                        },
                                      ],
                                    }}
                                    pointer={{
                                      color: "#345243",
                                      length: 0.8,
                                      width: 15,
                                    }}
                                    labels={{
                                      valueLabel: {
                                        formatTextValue: (value) => value,
                                        style: {
                                          fill: "cyan",
                                          textShadow: "none",
                                          stroke: "CaptionText",
                                          strokeWidth: 0.8,
                                          fontWeight: "bolder",
                                          fontSize: 44,
                                          display: "none",
                                        },
                                      },
                                      tickLabels: {
                                        type: "outer",
                                        valueConfig: {
                                          formatTextValue: (value) =>
                                            value + "ºC",
                                          fontSize: 10,
                                        },
                                        ticks:
                                          k?.title === "CT2-Percentage" ||
                                          k?.title === "CT2P"
                                            ? graphCTPTicks
                                            : graphTicks,
                                        defaultTickValueConfig: {
                                          style: { fill: "black" },
                                        },
                                      },
                                    }}
                                    value={k?.value}
                                    minValue={
                                      k?.title === "CT2-Percentage" ||
                                      k?.title === "CT2P"
                                        ? 0
                                        : -50
                                    }
                                    maxValue={150}
                                  />
                                  <p className="text-center text-2xl font-bold mt-0 text-primary">
                                    {k?.value}
                                  </p>
                                  <p className="text-center text-2xl font-bold mt-0">
                                    {k.title}
                                  </p>
                                </div>
                              </div>
                              <div className="col-3"></div>
                            </>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <h1 className="text-center text-2xl text-bolder mb-2">
                          Alarm/Trip Indication
                        </h1>
                        <div className="sm:flex sm:flex-row flex-col justify-around row">
                          {btnArr?.map((b, idxq) => {
                            if (!b.value) {
                              return null;
                            }
                            return (
                              <div
                                key={`${idxq}_#${b.title}`}
                                className={`text-black border-2 border-slate-700 py-2 rounded-full text-center col-12 col-sm-2 md:mt-0 mt-2 ${
                                  b.value === "OFF"
                                    ? "bg-[#5BE12C]"
                                    : "bg-[#ea4228]"
                                }`}
                              >
                                {b.title}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPR703RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR703RC") {
              const { gaugeArr, btnArr } = processData(d, keys, btnKeys);
              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {gaugeArr?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="col-12 col-md-6"
                              key={`${k?.value}_${index}`}
                            >
                              <GaugeComponent
                                key={generateKey(
                                  `${d.name}_${idxe}`,
                                  containerWidth
                                )}
                                type="semicircle"
                                arc={{
                                  width: 0.2,
                                  padding: 0.005,
                                  cornerRadius: 1,
                                  subArcs: [
                                    {
                                      limit: 90,
                                      color: "#5BE12C",
                                      showTick: true,
                                    },
                                    {
                                      limit: 110,
                                      color: "#F5CD19",
                                    },
                                    {
                                      color: "#EA4228",
                                    },
                                  ],
                                }}
                                pointer={{
                                  color: "#345243",
                                  length: 0.8,
                                  width: 15,
                                }}
                                labels={{
                                  valueLabel: {
                                    formatTextValue: (value) => value,
                                    style: {
                                      fill: "cyan",
                                      textShadow: "none",
                                      stroke: "CaptionText",
                                      strokeWidth: 0.8,
                                      fontWeight: "bolder",
                                      fontSize: 44,
                                      display: "none",
                                    },
                                  },
                                  tickLabels: {
                                    type: "outer",
                                    valueConfig: {
                                      formatTextValue: (value) => value + "ºC",
                                      fontSize: 10,
                                    },
                                    ticks:
                                      k?.title === "CT2-Percentage" ||
                                      k?.title === "CT2P"
                                        ? graphCTPTicks
                                        : graphTicks,

                                    defaultTickValueConfig: {
                                      style: { fill: "black" },
                                    },
                                  },
                                }}
                                value={k?.value}
                                minValue={
                                  k?.title === "CT2-Percentage" ||
                                  k?.title === "CT2P"
                                    ? 0
                                    : -50
                                }
                                maxValue={150}
                              />
                              <p className="text-center text-2xl font-bold mt-0 text-primary">
                                {k?.value}
                              </p>
                              <p className="text-center text-2xl font-bold mt-0">
                                {k.title}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <h1 className="text-center text-2xl text-bolder mb-2">
                          Alarm/Trip Indication
                        </h1>
                        <div className="sm:flex sm:flex-row flex-col justify-around row">
                          {btnArr?.map((b, idxq) => {
                            if (!b.value) {
                              return null;
                            }
                            return (
                              <div
                                key={`${idxq}_#${b.title}`}
                                className={`text-black border-2 border-slate-700 py-2 rounded-full text-center col-12 col-sm-3 m-1 ${
                                  b.value === "OFF"
                                    ? "bg-[#5BE12C]"
                                    : "bg-[#ea4228]"
                                }`}
                              >
                                {b.title}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPR106RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR106RC") {
              const { gaugeArr, btnArr } = processData(
                d,
                tpr104RCKeys,
                btnKeys
              );

              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {gaugeArr?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="col-12 col-md-6"
                              key={`${k?.value}_${index}`}
                            >
                              <GaugeComponent
                                key={generateKey(
                                  `${d.name}_${idxe}`,
                                  containerWidth
                                )}
                                type="semicircle"
                                arc={{
                                  width: 0.2,
                                  padding: 0.005,
                                  cornerRadius: 1,
                                  subArcs: [
                                    {
                                      limit: 180,
                                      color: "#5BE12C",
                                      showTick: true,
                                    },
                                    {
                                      limit: 240,
                                      color: "#F5CD19",
                                    },
                                    {
                                      color: "#EA4228",
                                    },
                                  ],
                                }}
                                pointer={{
                                  color: "#345243",
                                  length: 0.8,
                                  width: 15,
                                }}
                                labels={{
                                  valueLabel: {
                                    formatTextValue: (value) => value,
                                    style: {
                                      fill: "cyan",
                                      textShadow: "none",
                                      stroke: "CaptionText",
                                      strokeWidth: 0.8,
                                      fontWeight: "bolder",
                                      fontSize: 44,
                                      display: "none",
                                    },
                                  },
                                  tickLabels: {
                                    type: "outer",
                                    valueConfig: {
                                      formatTextValue: (value) => value + "ºC",
                                      fontSize: 8,
                                    },
                                    ticks: standardTicks,
                                    defaultTickValueConfig: {
                                      style: { fill: "black" },
                                    },
                                  },
                                }}
                                value={k?.value}
                                minValue={
                                  k?.title === "CT2-Percentage" ||
                                  k?.title === "CT2P"
                                    ? 0
                                    : -50
                                }
                                maxValue={300}
                              />
                              <p className="text-center text-2xl font-bold mt-0 text-primary">
                                {k?.value}
                              </p>
                              <p className="text-center text-2xl font-bold mt-0">
                                {k.title}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <h1 className="text-center text-2xl text-bolder mb-2">
                          Alarm/Trip Indication
                        </h1>
                        {!!btnArr?.length ? (
                          <div className="sm:flex sm:flex-row flex-col justify-around row">
                            {btnArr?.map((b, idxq) => {
                              if (!b.value) {
                                return null;
                              }
                              return (
                                <div
                                  key={`${idxq}_#${b.title}`}
                                  className={`text-black border-2 border-slate-700 py-2 rounded-full text-center col-12 col-sm-2 md:mt-0 mt-2 ${
                                    b.value === "OFF"
                                      ? "bg-[#5BE12C]"
                                      : "bg-[#ea4228]"
                                  }`}
                                >
                                  {b.title}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div>
                            <p className="text-center">No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}

        {data?.some((d) => d?.name === "TPR108RC") &&
          data?.map((d, idxe) => {
            if (d?.name === "TPR108RC") {
              const { gaugeArr, btnArr } = processData(
                d,
                tpr104RCKeys,
                btnKeys
              );
              return (
                <div key={idxe} className="col-12 col-md-6 mb-3">
                  <div className="shadow rounded-lg mt-3 border h-full">
                    <div className="container p-3">
                      <div className="row mb-3">
                        <div className="col-12 col-md-4 text-left">
                          <p className="text-2xl text-orange-400 font-semibold">
                            {d?.name}
                          </p>
                        </div>
                        <div className="col-12 col-md-8 text-right m-auto">
                          <p className="text-md text-secondary">
                            {d?.DateTime &&
                              moment(d?.DateTime, "YYYYMMDDHHmm")?.format(
                                "D-MM-YYYY, h:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {gaugeArr?.map((k, index) => {
                          if (!k?.value && k?.value !== 0) {
                            return <></>;
                          }
                          if (k?.value === 555) {
                            return (
                              <div
                                className="col-12 col-md-6 text-center"
                                key={`${k?.value}_${index}`}
                              >
                                <p className="flex justify-center">
                                  <img
                                    src={"/alert.png"}
                                    alt="fault"
                                    width={100}
                                  />
                                </p>
                                <p className="font-bold text-red-600 text-2xl">
                                  Sensor Fault
                                </p>
                                <p className="font-bold text-xl">{k?.title}</p>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="col-12 col-md-6"
                              key={`${k?.value}_${index}`}
                            >
                              <GaugeComponent
                                key={generateKey(
                                  `${d.name}_${idxe}`,
                                  containerWidth
                                )}
                                type="semicircle"
                                arc={{
                                  width: 0.2,
                                  padding: 0.005,
                                  cornerRadius: 1,
                                  subArcs: [
                                    {
                                      limit: 180,
                                      color: "#5BE12C",
                                      showTick: true,
                                    },
                                    {
                                      limit: 240,
                                      color: "#F5CD19",
                                    },
                                    {
                                      color: "#EA4228",
                                    },
                                  ],
                                }}
                                pointer={{
                                  color: "#345243",
                                  length: 0.8,
                                  width: 15,
                                }}
                                labels={{
                                  valueLabel: {
                                    formatTextValue: (value) => value,
                                    style: {
                                      fill: "cyan",
                                      textShadow: "none",
                                      stroke: "CaptionText",
                                      strokeWidth: 0.8,
                                      fontWeight: "bolder",
                                      fontSize: 44,
                                      display: "none",
                                    },
                                  },
                                  tickLabels: {
                                    type: "outer",
                                    valueConfig: {
                                      formatTextValue: (value) => value + "ºC",
                                      fontSize: 8,
                                    },
                                    ticks: standardTicks,
                                    defaultTickValueConfig: {
                                      style: { fill: "black" },
                                    },
                                  },
                                }}
                                value={k?.value}
                                minValue={
                                  k?.title === "CT2-Percentage" ||
                                  k?.title === "CT2P"
                                    ? 0
                                    : -50
                                }
                                maxValue={300}
                              />
                              <p className="text-center text-2xl font-bold mt-0 text-primary">
                                {k?.value}
                              </p>
                              <p className="text-center text-2xl font-bold mt-0">
                                {k.title}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <h1 className="text-center text-2xl text-bolder mb-2">
                          Alarm/Trip Indication
                        </h1>
                        {!!btnArr?.length ? (
                          <div className="sm:flex sm:flex-row flex-col justify-around row">
                            {btnArr?.map((b, idxq) => {
                              if (!b.value) {
                                return null;
                              }
                              return (
                                <div
                                  key={`${idxq}_#${b.title}`}
                                  className={`text-black border-2 border-slate-700 py-2 rounded-full text-center col-12 col-sm-2 md:mt-0 mt-2 ${
                                    b.value === "OFF"
                                      ? "bg-[#5BE12C]"
                                      : "bg-[#ea4228]"
                                  }`}
                                >
                                  {b.title}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div>
                            <p className="text-center">No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>
      {/* )} */}
    </>
  );
};

export default CardData;
