export const myHeaders = () => {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
    "User-Id": userData.id,
    role: userData.role,
  };
};
