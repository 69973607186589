import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { getDataByDate } from "../../redux/actions/admin/getDataByDate";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Pagination from "../../commoncomponents/Pagination";
import { getEventsTable } from "../../redux/actions/events/getEventsTable";
import { Dropdown } from "primereact/dropdown";
import { updateEventStatus } from "../../redux/actions/events/updateEventStatus";
import { clearupdateEventStatusState } from "../../redux/reducers/events/updateEventStatusReducer";
import { generateData, relays } from "../../utils/generateRecords";
import { getDataInXslx } from "../../redux/actions/admin/getDataInXslx";
import { cleargetDataInXslxReducer } from "../../redux/reducers/admin/getDataInXslxReducer";
import { getEventsTableXslx } from "../../redux/actions/admin/getEventsTableXslx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import Loading from "../../commoncomponents/Loading";

const actionsData = [
  { name: "Unacknowledged", value: "unacknowledged" },
  { name: "Acknowledged", value: "acknowledged" },
  { name: "Resolved", value: "resolved" },
  { name: "All", value: "all" },
];

const RealTimeData = ({
  tabIndex,
  currentPage,
  setCurrentPage,
  clearDateFilter,
  setClearDateFilter,
  filterData,
  activeParentTab,
  setResetFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const dispatch = useDispatch();

  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const savedFilter = localStorage.getItem(
    `dateFilter_${activeParentTab}_${tabIndex}`
  )
    ? localStorage.getItem(`dateFilter_${activeParentTab}_${tabIndex}`)
    : localStorage.getItem(`realTimeFilter_${activeParentTab}_${tabIndex}`);
  const [selectedActions, setSelectedActions] = useState({});
  const updateEventStatusData = useSelector(
    (state) => state.updateEventStatusData
  );
  const getDataInXslxData = useSelector((state) => state.getDataInXslxData);

  useEffect(() => {
    const existingFilters = localStorage.getItem(
      `realTimeFilter_${activeParentTab}_${tabIndex}`
    );
    if (existingFilters?.split(",")?.[0]) {
      setStartDate(new Date(existingFilters?.split(",")?.[0]));
    }
    if (existingFilters?.split(",")?.[1]) {
      setEndDate(new Date(existingFilters?.split(",")?.[1]));
    }
  }, [activeParentTab, tabIndex]);

  useEffect(() => {
    if (getDataInXslxData?.data?.data) {
      (async () => {
        const response = await fetch(
          `${process.env.REACT_APP_TEST_API}/${getDataInXslxData?.data?.data}`
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${getDataInXslxData?.data?.data}`?.slice(14);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        dispatch(cleargetDataInXslxReducer());
      })();
    }
    if (getDataInXslxData?.eventData?.data) {
      (async () => {
        const response = await fetch(
          `https://api.embelink.com/${getDataInXslxData?.eventData?.data}`
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${getDataInXslxData?.eventData?.data}`?.slice(14);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        dispatch(cleargetDataInXslxReducer());
      })();
    }
  }, [getDataInXslxData, dispatch]);

  const handleFilterDate = (page) => {
    setIsLoading(true);
    setResetFilter(true);
    const sDate = startDate;
    const eDate = endDate;
    const data = {
      startDate: sDate,
      endDate: eDate,
      page: page,
      table: tabIndex,
      searchKey: globalFilterValue,
      actionType: selectedAction,
    };
    const dateFilters = [sDate, eDate];
    if (page === 0) {
      setCurrentPage(1);
      localStorage.removeItem(`dateFilter_${activeParentTab}_${tabIndex}`);
      localStorage.setItem(
        `realTimeFilter_${activeParentTab}_${tabIndex}`,
        dateFilters
      );
    }
    if (activeParentTab === 3) {
      dispatch(getDataByDate(data));
      return;
    }
    dispatch(getEventsTable(data));
  };

  const handleDayWiseData = (days) => {
    let previousDate = new Date();
    if (days === 1) {
      previousDate.setDate(previousDate.getDate() - 1);
    } else if (days === 7) {
      previousDate.setDate(previousDate.getDate() - 7);
    } else if (days === 15) {
      previousDate.setDate(previousDate.getDate() - 15);
    } else if (days === 30) {
      previousDate.setDate(previousDate.getDate() - 30);
    } else if (days === "1hr") {
      previousDate.setHours(previousDate.getHours() - 1);
    }
    setStartDate(previousDate);
    setEndDate(new Date());
    setResetFilter(true);
    const data = {
      startDate: previousDate,
      endDate: new Date(),
      page: 0,
      searchKey: globalFilterValue,
      actionType: selectedAction,
      table: tabIndex,
    };
    const dateFilters = [previousDate, new Date(), days];
    localStorage.removeItem(`realTimeFilter_${activeParentTab}_${tabIndex}`);
    localStorage.setItem(
      `dateFilter_${activeParentTab}_${tabIndex}`,
      dateFilters
    );
    if (activeParentTab === 3) {
      dispatch(getDataByDate(data));
      return;
    }
    dispatch(getEventsTable(data));
  };

  const handleExportxlsx = (page, fileType) => {
    setIsLoading(true);
    setResetFilter(false);
    const data = {
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      page: page,
      table: tabIndex,
      is_download: true,
      file_type: fileType,
    };
    if (activeParentTab === 3) {
      dispatch(getDataInXslx(data));
      return;
    }
    dispatch(getEventsTableXslx(data));
  };

  useEffect(() => {
    if (clearDateFilter) {
      // setStartDate("");
      // setEndDate("");
      setClearDateFilter(false);
    }
  }, [clearDateFilter]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    setResetFilter(true);
    const data = {
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      page: 0,
      table: tabIndex,
      searchKey: value,
      actionType: selectedAction,
    };
    setIsLoading(true);
    if (activeParentTab === 3) {
      dispatch(getDataByDate(data));
      return;
    }
    dispatch(getEventsTable(data));
  };

  useEffect(() => {
    if (updateEventStatusData?.data?.status === true) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: updateEventStatusData?.data.message,
        life: 3000,
      });
      dispatch(clearupdateEventStatusState());
    } else if (updateEventStatusData?.data?.status === false) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: updateEventStatusData?.data.message,
        life: 3000,
      });
      dispatch(clearupdateEventStatusState());
    } else if (updateEventStatusData?.error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: updateEventStatusData.error,
        life: 3000,
      });
      dispatch(clearupdateEventStatusState());
    }
  }, [updateEventStatusData, dispatch, tabIndex]);

  useEffect(() => {
    if (!filterData?.loading) {
      setIsLoading(false);
    }
  }, [filterData]);

  const renderHeader = () => {
    return (
      <>
        <div className="flex flex-col md:flex-row lg:ustify-between">
          <div className="flex flex-col md:flex-row">
            <div className="flex-col items-center mb-2 md:mb-0 md:mr-1">
              <span className="text-sm font-normal">Search:</span>
              <InputText
                className="form-control !border-gray-400 !border-1 w-full md:!w-[140px] !rounded-md !p-[6px]"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Search"
              />
            </div>
            <div className="mb-2 md:mb-0 md:mr-1 flex flex-col items-start">
              <label
                htmlFor="calendar-start"
                className="text-sm font-normal mb-1"
              >
                Start Date:
              </label>
              <Calendar
                id="calendar-start"
                value={startDate}
                className="border-1 !border-gray-400 rounded-md w-full md:!w-[140px] !p-[6px] dateTime-realtable"
                onChange={(e) => setStartDate(e.value)}
                showButtonBar
                hourFormat="12"
                showTime
              />
            </div>
            <div className="mb-2 md:mb-0 md:mr-1 flex flex-col items-start">
              <label
                htmlFor="calendar-end"
                className="text-sm font-normal mb-1"
              >
                End Date:
              </label>
              <Calendar
                id="calendar-end"
                value={endDate}
                className="border-1 !border-gray-400 rounded-md w-full md:!w-[140px] !p-[6px] dateTime-realtable"
                onChange={(e) => setEndDate(e.value)}
                showButtonBar
                hourFormat="12"
                showTime
              />
            </div>
            <div className="flex items-end justify-end mb-2 md:mb-0">
              <button
                className="btn btn-primary w-full md:mr-1"
                onClick={() => handleFilterDate(0)}
              >
                {isLoading ? (
                  <div className="px-2.5">
                    <Loading width={23} height={23} />
                  </div>
                ) : (
                  <span>Apply</span>
                )}
              </button>
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex justify-between md:justify-end md:items-end mb-2 md:mb-0">
              <button
                className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                  savedFilter?.split(",")?.[2] === "1hr" ? "active" : ""
                }`}
                onClick={() => handleDayWiseData("1hr")}
              >
                <span className="text-sm">1 Hour</span>
              </button>
              <button
                className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                  savedFilter?.split(",")?.[2] === "1" ? "active" : ""
                }`}
                onClick={() => handleDayWiseData(1)}
              >
                <span className="text-sm">24 Hours</span>
              </button>
              <button
                className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                  savedFilter?.split(",")?.[2] === "7" ? "active" : ""
                }`}
                onClick={() => handleDayWiseData(7)}
              >
                <span className="text-sm">7 Days</span>
              </button>
              <button
                className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                  savedFilter?.split(",")?.[2] === "15" ? "active" : ""
                }`}
                onClick={() => handleDayWiseData(15)}
              >
                <span className="text-sm">15 Days</span>
              </button>
              <button
                className={`btn btn-outline-primary ml-1 md:ml-0 md:mr-2 ${
                  savedFilter?.split(",")?.[2] === "30" ? "active" : ""
                }`}
                onClick={() => handleDayWiseData(30)}
              >
                <span className="text-sm">30 Days</span>
              </button>
            </div>
            <div className="flex justify-between md:justify-end md:items-end mb-2 md:mb-0">
              <button
                className="btn btn-secondary flex items-center mr-2"
                onClick={() => handleExportxlsx(0, "xlsx")}
              >
                <div className="font-semibold text-sm">XLSX</div>
                <div className="ml-1">
                  <FontAwesomeIcon icon={faArrowAltCircleDown} />
                </div>
              </button>
              <button
                className="btn btn-secondary flex items-center"
                onClick={() => handleExportxlsx(0, "csv")}
              >
                <div className="font-semibold text-sm">CSV</div>
                <div className="ml-1">
                  {/* <img
                    src="/download.png"
                    alt="donload"
                    width={24}
                    height={20}
                  /> */}
                  <FontAwesomeIcon icon={faArrowAltCircleDown} />
                </div>
              </button>
            </div>
          </div>
        </div>
        {activeParentTab === 2 && (
          <div className="flex justify-end">
            <Dropdown
              value={selectedAction}
              onChange={(e) => {
                setResetFilter(true);
                setSelectedAction(e.value);
                const data = {
                  startDate: startDate ? startDate : "",
                  endDate: endDate ? endDate : "",
                  page: 0,
                  table: tabIndex,
                  searchKey: globalFilterValue,
                  actionType: e.value,
                };
                dispatch(getEventsTable(data));
              }}
              options={actionsData}
              optionLabel="name"
              placeholder="Action type"
              className="w-full md:w-[170px] !border border-[#ced4da] hover:border-[#fe4c4c] h-[40px] event-filter"
            />
          </div>
        )}
      </>
    );
  };

  const header = renderHeader();

  const handleUpdateStatus = (rData, e) => {
    const { value } = e.target;
    setSelectedActions((prevSelectedActions) => ({
      ...prevSelectedActions,
      [rData.id]: value,
    }));
    const data = {
      tableName: tabIndex,
      id: rData.id,
      status: value?.slice(-1),
    };
    dispatch(updateEventStatus(data));
  };

  const actionBodyTemplate = (rowData) => {
    const options = [
      { id: rowData?.id, name: "Acknowledged", value: `${rowData?.id}_1` },
      { id: rowData?.id, name: "Resolved", value: `${rowData?.id}_2` },
    ];

    const selectedValue =
      selectedActions[rowData.id] ||
      (rowData?.status &&
        options?.filter((i) => i?.value === `${rowData?.id}_1`)?.[0]?.value);

    if (rowData.status === "2") {
      return (
        <InputText
          className="font-bold text-center h-[2.13rem]"
          value={"Resolved"}
          disabled
        />
      );
    }

    // if (rowData.status === "1") {
    //   return (
    //     <InputText
    //       className="font-bold text-center h-[2.13rem]"
    //       value={"Acknowledged"}
    //       disabled
    //     />
    //   );
    // }
    return (
      <>
        <Dropdown
          options={options}
          // value={actionValue}
          value={selectedValue}
          onChange={(e) => handleUpdateStatus(rowData, e)}
          optionLabel="name"
          placeholder="Select Action"
          className="md:w-14rem !border border-[#ced4da] hover:border-[#fe4c4c] rounded z-1000 eventDropdown"
        />
      </>
    );
  };

  const totalMinutes = 60;
  const generatedData =
    activeParentTab !== 2 && savedFilter?.split(",")?.[2] === "1hr"
      ? generateData(totalMinutes, tabIndex)
      : null;
  const recordsPerPage = 10;
  const totalRecords = !!filterData?.data?.data?.length
    ? Number(filterData?.data?.totalRows)
    : totalMinutes;
  const eventTableIndex = [];
  for (let i = 1; i <= Number(filterData?.data?.totalRows); i++) {
    eventTableIndex.push(i);
  }
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const indexBodyTemplate = (rowData, options) => {
    return currentPage * recordsPerPage + options.rowIndex + 1;
  };

  return (
    <div className="main-panel">
      <div className="table-card p-0 pt-2">
        <DataTable
          value={
            !!filterData?.data?.data?.length
              ? filterData?.data?.data
              : generatedData
          }
          // filters={filters}
          removableSort
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          className="bg-none"
          paginator={
            !filterData?.data?.data?.length && activeParentTab !== 2 && true
          }
          rows={!filterData?.data?.data?.length && activeParentTab !== 2 && 10}
          rowsPerPageOptions={
            !filterData?.data?.data?.length &&
            activeParentTab !== 2 && [5, 10, 25, 50]
          }
          emptyMessage="No Data found."
          showGridlines
        >
          {!!filterData?.data?.data?.length
            ? Object.keys(filterData?.data?.data?.[0]).map((k, idx) => {
                if (
                  k === "PrimKey" ||
                  k === "status" ||
                  k === "HardwareVerison" ||
                  k === "SoftwareVerison" ||
                  k === "SerialNumber"
                ) {
                  return null;
                }
                if (k === "id") {
                  return (
                    <Column
                      header="Sr. No."
                      body={indexBodyTemplate}
                      className="border-1 text-[14px] p-1 text-center"
                    />
                  );
                }
                if (relays[k]) {
                  return (
                    <Column
                      header={relays[k]}
                      className="border-1 text-[14px] p-1 text-center"
                      field={k}
                      sortable={true}
                    />
                  );
                } else {
                  return (
                    <Column
                      key={`${idx}_#`}
                      className="border-1 text-[14px] p-1 text-center"
                      field={k}
                      header={k}
                      sortable={k === "id" ? false : true}
                    ></Column>
                  );
                }
              })
            : activeParentTab !== 2 &&
              generatedData &&
              Object.keys(generatedData?.[0]).map((k, idx) => {
                if (k === "PrimKey" || k === "created_at") {
                  return null;
                } else {
                  return (
                    <Column
                      key={`${idx}_#`}
                      className="border-1 text-[14px] p-1 text-center"
                      field={k}
                      header={k}
                      sortable={k === "id" ? false : true}
                    ></Column>
                  );
                }
              })}
          {!!filterData?.data?.data?.length && activeParentTab === 2 && (
            <Column
              className="border-1 text-[14px] p-1 text-center content-center"
              header={"Action"}
              body={actionBodyTemplate}
            />
          )}
        </DataTable>
        {!!filterData?.data?.data?.length && totalPages > 1 && (
          <Pagination
            currentPage={currentPage + 1}
            totalPages={totalPages}
            onPageChange={(page) => {
              setCurrentPage(page - 1);
              handleFilterDate(page);
            }}
          />
        )}
        {/* {activeParentTab === 2 && !filterData?.data?.data?.length && (
          <p className="text-center">No Data Found</p>
        )} */}
        <Toast ref={toast} className="text-sm" />
      </div>
    </div>
  );
};

export default RealTimeData;
